import { axios } from '../axios';

export const putUploadAvatar = (body: { file: File }) => {
  const formData = new FormData();
  formData.append('avatar', body.file);
  return axios.put<void>('/me/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteAvatar = () => {
  return axios.delete<void>('/me/avatar');
};

export const getReferralCode = () => {
  return axios
    .get<{ referral_code: string }>('/me/referral-code')
    .then((res) => res.data);
};
