/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TeamResponse } from './teams/teams.api';
import { OfferAssignmentResponse } from './offer-assignments/offer-assignments';
import { SubscriptionTierType } from '../models/user';
import { BranchResponse } from './branches/branches';

export enum ResponseFileTypeEnum {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export enum ExportFileTypeEnum {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export interface HistogramViewResult {
  from_value: number;
  to_value: number;
  count: number;
}
export interface GenerateOfferDescriptionResponse {
  /** Description */
  description: string;
}

export interface GenerateOfferDescriptionRequest {
  /** Offer */
  offer_data: Record<string, any>;
  offer_type: OfferTypeEnum;
}

export interface AddOfferToClientOfferSearch {
  offer_id: string;
  offer_type: OfferTypeEnum;
}

export interface MapOfferListResponse {
  results: MapOffer[];
  page: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
}

export interface UpdateOfferVariantRequest {
  variant_data: any;
  photos_data_urls: string[];
}

export interface OfferVariantResponse {
  offer_variant_id: string;
  original_offer_id: string;
  original_offer_type: OfferTypeEnum;
  variant_data: Record<string, any>;

  offer_variant_feedback?: OfferVariantFeedbackResponse;
  organization_id: string;
  created_at: string;
  photos_urls: string[];
}

export enum OfferVariantFeedback {
  YES = 'YES',
  MAYBE = 'MAYBE',
  NO = 'NO',
  EXPIRED = 'EXPIRED',
}

export interface OfferVariantFeedbackResponse {
  offer_variant_feedback_id: string;
  comment?: string;
  feedback?: OfferVariantFeedback;
}

export interface OfferVariantDetailsResponse {
  offer_variant_id: string;
  original_offer_id: string;
  original_offer_type: OfferTypeEnum;
  variant_data: Record<string, any>;

  organization_id: string;
  created_at: string;
  photos_urls: string[];

  created_by: UserPublicResponse;
  assigned_to?: UserPublicResponse;
  organization: OrganizationPublicResponse;
  offer_variant_feedback?: OfferVariantFeedbackResponse;
}

export interface AddOfferVariantRequest {
  original_offer_id: string;
  original_offer_type: OfferTypeEnum;
  variant_data: any;
  photos_data_urls: string[];
}

export interface AddOfferVariantResponse {
  offer_variant_id: string;
}
export interface UpdateOrganizationSettingsRequest {
  name: string;
  website: string | null;
  phone_number: string | null;
  address: string | null;
  legal_name: string | null;
}
export interface ActivityStatusResponse {
  id: string;
  offer_id: string;
  offer_activity_status: boolean;
  checked_at: string;
  tag: string;
  is_current: boolean;
}

export interface BookRawTables {
  id: string; // UUID
  hyb_number: string;
  i_0: string;
  i_sp: string;
  ii: string;
  iii: string;
  iv: string;
  checked_at: string; // datetime as string
}

export interface MonitoredBook {
  id: string; // UUID
  hyb_number: string;
  checked_at?: string; // datetime as string
  subscriber_id: string; // UUID
  should_notify: boolean;
  notify_until?: string; // datetime as string
  is_empty?: boolean;
  is_closed?: boolean;
}

export interface MonitoredBookWithRawTables extends MonitoredBook {
  raw_tables: BookRawTables;
}

export enum ListMonitoredBooksOrder {
  HYB_ASC = 'HYB_ASC',
  HYB_DESC = 'HYB_DESC',
}

export enum BookState {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  EMPTY = 'EMPTY',
}

export interface ListMonitoredBooksOptions {
  name?: string;
  state?: BookState;
  is_notifying?: boolean;
  order?: ListMonitoredBooksOrder;
}

export interface ListMonitoredBooksResponse {
  total_items_no_filters: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
  page: number;
  books: MonitoredBook[];
}

export enum OfferSearchStateEnum {
  PRESELECTED = 'PRESELECTED',
  REJECTED = 'REJECTED',
  PREPARING = 'PREPARING',
  SHOWN_TO_CLIENT = 'SHOWN_TO_CLIENT',
  ACCEPTED_BY_CLIENT = 'ACCEPTED_BY_CLIENT',
  REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
}

export enum OfferSearchAddMethodTypeEnum {
  MANUAL = 'MANUAL',
  VIEW_SUBSCRIPTION = 'VIEW_SUBSCRIPTION',
}

export interface ClientSearchOffersCountResponse {
  state: OfferSearchStateEnum; // Assuming SmsCampaignState is defined elsewhere
  offers_count: number;
}

export interface ClientSearchSelectedOffersResponse {
  client_search_selected_offer_id: string; // UUIDs are represented as strings in TypeScript
  offer: OfferResult; // Assuming OfferWithStatusResult is defined elsewhere
  offer_type: OfferTypeEnum;
  offer_id: string;
  state: OfferSearchStateEnum;
  add_method: OfferSearchAddMethodTypeEnum;
  created_at: string; // datetime as string
  client_offer_search_id: string; // UUID as string
  offer_variant?: OfferVariantResponse;
  watermark_removal_lro?: GetWatermarkRemovalLroBase;
}

export interface ClientSearchSharedOffersResponse {
  client_search_selected_offer_id: string; // UUIDs are represented as strings in TypeScript
  offer_type: OfferTypeEnum;
  offer_id: string;
  state: OfferSearchStateEnum;
  add_method: OfferSearchAddMethodTypeEnum;
  created_at: string; // datetime as string
  client_offer_search_id: string; // UUID as string
  offer_variant?: OfferVariantResponse;
}

export interface AddOfferToClientSearchResponse {
  client_search_selected_offer_id: string; // UUIDs are represented as strings in TypeScript
}

export interface ClientSearchSelectedOfferListResponse {
  results: ClientSearchSelectedOffersResponse[];
  page: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
}

export interface ClientSearchSharedOfferListResponse {
  results: ClientSearchSharedOffersResponse[];
  search?: ClientOfferSearchForSharedOffers;
  page: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
}

export enum ClientSearchPaymentMethodEnum {
  CASH = 'CASH',
  LOAN = 'LOAN',
  OTHER = 'OTHER',
}

export interface ClientOfferSearchBase {
  client_offer_search_id: string; // UUIDs are strings in TypeScript
  created_at: string; // datetime is represented as a string
  updated_at: string; // datetime is represented as a string
  title: string;
  description: string;
  is_closed: boolean;
  payment_method?: ClientSearchPaymentMethodEnum;
}

export interface ClientOfferSearchResponse extends ClientOfferSearchBase {
  client: ClientDetailsResponse;
  created_by: UserPublicResponse;
  assigned_to?: UserPublicResponse;
  views: ViewResponse[];
}

export interface ClientOfferSearchListItemResponse
  extends ClientOfferSearchBase {
  assigned_to?: UserPublicResponse;
  client: ClientDetailsResponse;
}

export interface ClientOfferSearchListItemForOfferDetails
  extends ClientOfferSearchListItemResponse {
  selected_offer?: {
    client_search_selected_offer_id: string;
    offer_id: string;
    offer_type: OfferTypeEnum;
    state: OfferSearchStateEnum;
    add_method: OfferSearchAddMethodTypeEnum;
    created_at: string;
    client_offer_search_id: string;
    offer_variant_id?: string;
  };
}

export interface ClientOfferSearchForSharedOffers
  extends ClientOfferSearchBase {
  client: ClientDetailsResponse;
  organization: OrganizationPublicResponse;
  created_by: UserPublicResponse;
  assigned_to: UserPublicResponse;
}

export interface ClientOfferSearchCreateResponse {
  client_offer_search_id: string; // UUIDs are strings in TypeScript
}

export interface ClientOfferSearchListResponse {
  results: ClientOfferSearchListItemResponse[];
  page: number;
  total_items_no_filters: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
}

export interface AddClientOfferSearchRequest {
  title: string;
  description: string;
  client_id: string; // UUID
  assigned_to_id?: string; // UUID
  views: string[]; // list of UUIDs
  payment_method?: ClientSearchPaymentMethodEnum;
}

export interface UpdateClientOfferSearchRequest {
  title: string;
  description: string;
  client_id: string; // UUID
  is_closed: boolean;
  assigned_to_id?: string; // UUID
  views: string[]; // list of UUIDs
  payment_method?: ClientSearchPaymentMethodEnum;
}

export enum FeatureFlag {
  MANUAL_SMS_ENABLED = 'manual_sms_enabled',
  PHONE_CALLS_ENABLED = 'phone_call_app_enabled',
  SMS_CAMPAIGNS_ENABLED = 'sms_campaigns_enabled',
  ALWAYS_DISABLE = 'always_disable',
  GEOPORTAL_PARCELS_ENABLED = 'geoportal_parcels_enabled',
  OFFER_EXPORT_ENABLED = 'offer_export_enabled',
  MEETINGS_ENABLED = 'meetings_enabled',
  FAIR_MEETINGS_ENABLED_ASSIGNMENT = 'fair_meetings_assignment_enabled',
  LEAD_PANEL_ENABLED = 'lead_panel_enabled',
  DOCUMENTS_ENABLED = 'documents_enabled',
  SALES_ASSISTANT_ENABLED = 'sales_assistant_enabled',
}

export interface SendSmsRequest {
  message: string;
  receiver_phone_number: string;
  offer_id: string;
}

export enum SmsType {
  MANUAL = 'MANUAL',
  AUTOMATED = 'AUTOMATED',
}

export enum MessageClassificationEnum {
  IS_EXPIRED = 'IS_EXPIRED',
  IS_ACTIVE = 'IS_ACTIVE',
  NO_AGENCY = 'NO_AGENCY',
  DETAIL_PROVIDED = 'DETAIL_PROVIDED',
  PLEASE_CONTACT = 'PLEASE_CONTACT',
  QUESTION = 'QUESTION',
  PLEASE_CONTACT_CITO = 'PLEASE_CONTACT_CITO',
}

export interface SmsResponseDistribution {
  message_classification?: MessageClassificationEnum | null;
  count: number;
}

export interface SmsSummaryResponse {
  date: string;
  sms_sent_count: number;
  sms_responses_count: number;
  sms_responses_distribution: SmsResponseDistribution[];
}

export interface IncomingSmsResponse {
  message: string;
  sender_phone_number: string;
  created_at: string;
  received_at: string;
  message_classification?: MessageClassificationEnum | null;
}

export interface SmsLogResponse {
  sms_id: string;
  sms_gate_id: string;
  created_at: string;
  sent_at: string;
  message: string;
  receiver_phone_number: string;
  offer_id?: string | null;
  offer_type?: OfferTypeEnum | null;
  responses: IncomingSmsResponse[];
  created_by: UserPublicResponse;
  sms_type: SmsType;
}

export interface SmsLogWithOffer {
  log: SmsLogResponse;
  offer?: OfferResult;
}

export interface SmsWithResponsesAndOffersFeed {
  sms_logs_with_offers: SmsLogWithOffer[];
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
  page: number;
}

export enum SmsCampaignState {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface SmsOutreachCampaignResponse {
  sms_outreach_campaign_id: string;
  created_at: string;
  title: string;
  description: string;
  sms_message: string;
  state: SmsCampaignState;

  created_by: UserPublicResponse;
  assigned_to: UserPublicResponse[];
  views: ViewResponse[];
}

export interface SmsOutreachCampaignListResponse {
  results: SmsOutreachCampaignResponse[];
  page: number;
  total_items: number;
  total_pages: number;
  has_next_page: boolean;
}

export interface AddSmsOutreachCampaignRequest {
  title: string;
  description: string;
  sms_message: string;
  views: string[];
}

export interface UpdateSmsOutreachCampaignRequest {
  title: string;
  description: string;
  sms_message: string;
  views: string[];
  assigned_to_ids: string[];
}

export interface ContactedClientResponse {
  phone_number: string;
  latest_contact_date: string;
}

export interface ClientDetailsResponse {
  client_id: string;
  created_by: UserPublicResponse;
  phone_number: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  description?: string | null;
  created_at: string;
}

export interface CreateClientResponse {
  client_id: string;
}

export interface ClientListResponse {
  client_id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  created_at: string;
}

export interface AddClientRequest {
  phone_number: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  description?: string | null;
}

export interface UpdateClientRequest {
  phone_number: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  description?: string | null;
}

export enum PriceType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  OFFER = 'OFFER',
}

export interface NBPPricesResponse {
  index: number;
  quarter: number;
  city: string;
  price_per_meter: string;
  year: number;
  nbp_price_type: PriceType;
  market_type: MarketTypeEnum;
}

export interface InvoiceResponse {
  invoice_id: string;
  organization_id: string;
  issue_date: string;
  net_amount: number;
  gross_amount: number;
  vat_rate: number;
  title: string;
  bank_account_number: string;
  settled: boolean;
}

export interface InvestMapResponse {
  id: string; // UUIDs are typically represented as strings in JavaScript/TypeScript
  title?: string;
  url: string;
  photos_urls?: string[];
  designer?: string;
  completion?: Date;
  investor?: string;
  construction?: string;
  category?: string;
  location_latitude?: number;
  location_longitude?: number;
  address?: string;
  description?: string;
}

export interface AVMResult {
  /** Predicted Price */
  predicted_price: number;

  /** Upper Confidence Interval 95 */
  upper_confidence_interval_95: number;

  /** Lower Confidence Interval 95 */
  lower_confidence_interval_95: number;
}

export interface EventSummaryResponse {
  id: string;
  ts: string;
  userid: string;
  type: string;
}

/**
 * Pydantic enum type for access_road field.
 */
export enum AccessRoadEnum {
  FIELD = 'FIELD',
  PAVED = 'PAVED',
  HARDENED = 'HARDENED',
  COBBLESTONE = 'COBBLESTONE',
  OTHER = 'OTHER',
  ASPHALT = 'ASPHALT',
  NONE = 'NONE',
}

export interface AddCallSummaryRequest {
  /** An enumeration. */
  result: PhoneCallResultEnum;

  /** Notes */
  notes?: string;

  /** Linked Calls */
  linked_calls: string[];

  /** Offer Id */
  offer_id: string;

  /** Phone Number */
  phone_number: string;
}

export interface AddCommentRequest {
  /** Contents */
  contents: string;
}

export interface AddOfferStatusRequest {
  /** An enumeration. */
  status: OfferStatus;
}

export interface AddOfferStatusResponse {
  /**
   * Offer Status Id
   * @format uuid
   */
  offer_status_id: string;
}

export interface AddViewRequest {
  /** Title */
  title: string;

  /** Sorts */
  sorts: object[];

  /** Filters */
  filters: object[];

  /** Description */
  description?: string;

  /** Offer types */
  offer_types: OfferTypeEnum[];

  /** An enumeration. */
  subscription_type?: SubscriptionType;
}

export interface AddressAutosuggestionResponse {
  /** Address City */
  address_city?: string;

  /** Address County */
  address_county?: string;

  /** Address Street */
  address_street?: string;

  /** Address State */
  address_state: string;

  /** Address Community */
  address_community?: string;

  /** Address District */
  address_district?: string;

  /** Address */
  address: string;
}

export interface FullAddressAutosuggestionResponse {
  address: string;
  address_country: string;
  address_state: string;
  address_county: string;
  address_community: string;
  address_city: string;
  address_district?: string;
  address_street?: string;
  address_street_number: string;
  address_postcode?: string;
  location_latitude: number;
  location_longitude: number;
}

export interface CommunityAutosuggestionResponse {
  address: string;
  address_state: string;
  address_county: string;
  address_community: string;
  geometry: object;
}

export interface AddressResponse {
  /** Id */
  id: string;

  /** Value */
  value: string;

  /** Has Children */
  has_children: boolean;
}

export interface AnalyticsRequest {
  /** Payload */
  payload: object;
}

/**
 * ApartmentRent object in the DB.
 */
export interface ApartmentRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Additional Fees */
  additional_fees?: number;

  /** Administrative Rent */
  administrative_rent?: number;

  /** Bathroom Count */
  bathroom_count?: number;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for building_type field. */
  building_type?: BuildingTypeEnum;

  /** Deposit */
  deposit?: number;

  /** Floor No Lower Bound */
  floor_no_lower_bound?: number;

  /** Floor No Upper Bound */
  floor_no_upper_bound?: number;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Has Balcony */
  has_balcony?: boolean;

  /** Has Basement */
  has_basement?: boolean;

  /** Has Elevator */
  has_elevator?: boolean;

  /** Has Terrace */
  has_terrace?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Interior Height */
  interior_height?: number;

  /** Is Furnished */
  is_furnished?: boolean;

  /** Is Gated Community */
  is_gated_community?: boolean;

  /** Pydantic enum type for kitchen_type field. */
  kitchen_type?: KitchenTypeEnum;

  /** Levels */
  levels?: number;

  /** Pydantic enum type for parking field. */
  parking?: ParkingEnum;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** Rooms */
  rooms?: number;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Total Levels In Building Lower Bound */
  total_levels_in_building_lower_bound?: number;

  /** Total Levels In Building Upper Bound */
  total_levels_in_building_upper_bound?: number;

  /** Pydantic enum type for windows_material field. */
  windows_material?: WindowsMaterialEnum;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * ApartmentSale object in the DB.
 */
export interface ApartmentSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Bathroom Count */
  bathroom_count?: number;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for building_type field. */
  building_type?: BuildingTypeEnum;

  /** Floor No Lower Bound */
  floor_no_lower_bound?: number;

  /** Floor No Upper Bound */
  floor_no_upper_bound?: number;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Has Balcony */
  has_balcony?: boolean;

  /** Has Basement */
  has_basement?: boolean;

  /** Has Elevator */
  has_elevator?: boolean;

  /** Has Terrace */
  has_terrace?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Interior Height */
  interior_height?: number;

  /** Is Furnished */
  is_furnished?: boolean;

  /** Is Gated Community */
  is_gated_community?: boolean;

  /** Pydantic enum type for kitchen_type field. */
  kitchen_type?: KitchenTypeEnum;

  /** Levels */
  levels?: number;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Pydantic enum type for parking field. */
  parking?: ParkingEnum;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Rent */
  rent?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** Rooms */
  rooms?: number;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Total Levels In Building Lower Bound */
  total_levels_in_building_lower_bound?: number;

  /** Total Levels In Building Upper Bound */
  total_levels_in_building_upper_bound?: number;

  /** Pydantic enum type for windows_material field. */
  windows_material?: WindowsMaterialEnum;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

export interface ArchiveOfferStatusesRequest {
  /** Status Ids */
  status_ids: string[];
}

/**
 * Pydantic enum type for attic field.
 */
export enum AtticEnum {
  NONE = 'NONE',
  NON_UTILITY = 'NON_UTILITY',
  UTILITY = 'UTILITY',
}

/**
 * Pydantic enum type for auction_offer_type field.
 */
export enum AuctionOfferTypeEnum {
  LAND = 'LAND',
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  GARAGE = 'GARAGE',
  WAREHOUSE = 'WAREHOUSE',
  COMMERCIAL_BUILDING = 'COMMERCIAL_BUILDING',
  OTHER = 'OTHER',
}

/**
 * Auction object in the DB.
 */
export interface AuctionResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for auction_offer_type field. */
  auction_offer_type?: AuctionOfferTypeEnum;

  /** Auction Signature */
  auction_signature?: string;

  /** Pydantic enum type for auction_type field. */
  auction_type?: AuctionTypeEnum;

  /** Bailiff */
  bailiff?: string;

  /** Bailiff Office */
  bailiff_office?: string;

  /** Court */
  court?: string;

  /**
   * Date Of Auction
   * @format date-time
   */
  date_of_auction?: string;

  /**
   * Date Of Pledge
   * @format date-time
   */
  date_of_pledge?: string;

  /** Estimated Price */
  estimated_price?: number;

  /** Initial Pledge */
  initial_pledge?: number;

  /** Mortgage Register */
  mortgage_register?: string;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Shares */
  shares?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for auction_type field.
 */
export enum AuctionTypeEnum {
  ONLINE = 'ONLINE',
  ON_SITE = 'ON_SITE',
}

export interface BodyLoginApiLoginPost {
  /**
   * Grant Type
   * @pattern password
   */
  grant_type?: string;

  /** Username */
  username: string;

  /** Password */
  password: string;

  /** Scope */
  scope?: string;

  /** Client Id */
  client_id?: string;

  /** Client Secret */
  client_secret?: string;
}

export interface BodyUploadUserAvatarApiMeAvatarPut {
  /**
   * Avatar
   * @format binary
   */
  avatar: File;
}

/**
 * Pydantic enum type for building_material field.
 */
export enum BuildingMaterialEnum {
  CONCRETE = 'CONCRETE',
  BRICK = 'BRICK',
  BLOCK = 'BLOCK',
  WOOD = 'WOOD',
  MIXED = 'MIXED',
  SILICATE = 'SILICATE',
  REINFORCED_CONCRETE = 'REINFORCED_CONCRETE',
  LARGE_SLAB = 'LARGE_SLAB',
  OTHER = 'OTHER',
  H_FRAME = 'H_FRAME',
  YTONG = 'YTONG',
}

/**
 * Pydantic enum type for building_stage field.
 */
export enum BuildingStageEnum {
  BASE_STAGE = 'BASE_STAGE',
  FRAME_STAGE = 'FRAME_STAGE',
  LOCKUP_STAGE = 'LOCKUP_STAGE',
  FINISHING_STAGE = 'FINISHING_STAGE',
  COMPLETION_STAGE = 'COMPLETION_STAGE',
}

/**
 * Pydantic enum type for building_type field.
 */
export enum BuildingTypeEnum {
  BLOCK_OF_FLATS = 'BLOCK_OF_FLATS',
  APARTMENT_COMPLEX = 'APARTMENT_COMPLEX',
  TENEMENT = 'TENEMENT',
  SEMI_DETACHED = 'SEMI_DETACHED',
  DETACHED = 'DETACHED',
  TERRACE_HOUSE = 'TERRACE_HOUSE',
  LOFT = 'LOFT',
  INFILL = 'INFILL',
  OTHER = 'OTHER',
}

export interface CallListOfferResponse {
  /** Offer */
  offer: object;
  status: OfferStatusResponse;

  /** Comments Count */
  comments_count: number;
  statuses_count: number;
  phone_call_summary_count: number;
  latest_phone_call_summary?: PhoneCallSummaryResponse;
}

export interface CallListResponse {
  /** Positions */
  positions: object;
}

export interface CallListSummaryResponse {
  /** An enumeration. */
  status: OfferStatus;

  /** Total Count */
  total_count: number;

  /** Today Count */
  today_count: number;
}

/**
 * An enumeration.
 */
export enum CallListType {
  PRIVATE = 'PRIVATE',
  ORGANIZATION = 'ORGANIZATION',
}

/**
 * An enumeration.
 */
export enum CallTypeEnum {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export interface ChangeOrganizationMemberPrivilegesRequest {
  /** An enumeration. */
  privilege: ChangeableOrganizationMemberPrivilege;
}

export interface ChangeUserPasswordRequest {
  /** Password */
  password: string;
}

/**
 * An enumeration.
 */
export enum ChangeableOrganizationMemberPrivilege {
  MEMBER = 'MEMBER',
  MODERATOR = 'MODERATOR',
}

export interface CommentResponse {
  /**
   * Comment Id
   * @format uuid
   */
  comment_id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Contents */
  contents: string;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  author: UserPublicResponse;
}

/**
 * CommercialBuildingRent object in the DB.
 */
export interface CommercialBuildingRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for location_type field. */
  location_type?: LocationTypeEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * CommercialBuildingSale object in the DB.
 */
export interface CommercialBuildingSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for location_type field. */
  location_type?: LocationTypeEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for construction_type field.
 */
export enum ConstructionTypeEnum {
  PARKING_SPACE = 'PARKING_SPACE',
  OTHER = 'OTHER',
  BRICK = 'BRICK',
  TIN_PLATE = 'TIN_PLATE',
  SHED = 'SHED',
}

export interface DetailedPhoneCallSummaryCountResult {
  /** Total Items */
  total_items: number;
}

export interface DetailedPhoneCallSummaryResponse {
  /**
   * Phone Call Summary Id
   * @format uuid
   */
  phone_call_summary_id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;
  user: UserPublicResponse;

  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;

  /** Phone Number */
  phone_number: string;

  /** Notes */
  notes?: string;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;

  /** An enumeration. */
  result: PhoneCallResultEnum;

  /** Linked Calls */
  linked_calls: PhoneCallResponse[];
}

/**
 * DownloadedImages object in the DB.
 */
export interface DownloadedImagesResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Source Urls */
  source_urls: string[];

  /** Downloaded Urls */
  downloaded_urls: string[];

  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

export interface DuplicateOfferResponse {
  /** Offer */
  offer:
    | ApartmentRentResponse
    | ApartmentSaleResponse
    | AuctionResponse
    | CommercialBuildingRentResponse
    | CommercialBuildingSaleResponse
    | GarageRentResponse
    | GarageSaleResponse
    | HouseRentResponse
    | HouseSaleResponse
    | IndustrialBuildingRentResponse
    | IndustrialBuildingSaleResponse
    | LandRentResponse
    | LandSaleResponse
    | OfficeRentResponse
    | OfficeSaleResponse
    | RoomRentResponse
    | WarehouseRentResponse
    | WarehouseSaleResponse
    | OfferResponse
    | (ApartmentRentResponse &
        ApartmentSaleResponse &
        AuctionResponse &
        CommercialBuildingRentResponse &
        CommercialBuildingSaleResponse &
        GarageRentResponse &
        GarageSaleResponse &
        HouseRentResponse &
        HouseSaleResponse &
        IndustrialBuildingRentResponse &
        IndustrialBuildingSaleResponse &
        LandRentResponse &
        LandSaleResponse &
        OfficeRentResponse &
        OfficeSaleResponse &
        RoomRentResponse &
        WarehouseRentResponse &
        WarehouseSaleResponse &
        OfferResponse);

  /** Comments Count */
  comments_count: number;

  /** Status Count */
  statuses_count: number;

  /** Count of Phone Call Summaries */
  phone_call_summary_count: number;
}

/**
 * Pydantic enum type for fence field.
 */
export enum FenceEnum {
  MESH = 'MESH',
  METAL = 'METAL',
  WOODEN = 'WOODEN',
  NONE = 'NONE',
  OTHER = 'OTHER',
  MIXED = 'MIXED',
}

/**
 * Pydantic enum type for floor_type field.
 */
export enum FloorTypeEnum {
  DUSTY = 'DUSTY',
  NON_DUSTY = 'NON_DUSTY',
}

export interface ForgotPasswordRequest {
  /** Email */
  email: string;
}

export interface UpdateUserConsentsRequest {
  is_app_consent_given: boolean;
  is_marketing_consent_given: boolean;
}
/**
 * GarageRent object in the DB.
 */
export interface GarageRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for construction_type field. */
  construction_type?: ConstructionTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * GarageSale object in the DB.
 */
export interface GarageSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for construction_type field. */
  construction_type?: ConstructionTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

export interface GeojsonResponse {
  /** Geometry */
  geometry: object;
}

export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/**
 * Pydantic enum type for heated_water field.
 */
export enum HeatedWaterEnum {
  MUNICIPAL = 'MUNICIPAL',
  GAS_HEATING = 'GAS_HEATING',
  SOLAR_HEATING = 'SOLAR_HEATING',
  ELECTRIC_HEATING = 'ELECTRIC_HEATING',
  OTHER_HEATING = 'OTHER_HEATING',
  NONE = 'NONE',
}

/**
 * Pydantic enum type for heating field.
 */
export enum HeatingEnum {
  MUNICIPAL = 'MUNICIPAL',
  GAS_HEATING = 'GAS_HEATING',
  SOLAR_HEATING = 'SOLAR_HEATING',
  ELECTRIC_HEATING = 'ELECTRIC_HEATING',
  OTHER_HEATING = 'OTHER_HEATING',
  NONE = 'NONE',
  TILED_STOVE = 'TILED_STOVE',
  BOILER_ROOM = 'BOILER_ROOM',
}

/**
 * HouseRent object in the DB.
 */
export interface HouseRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for access_road field. */
  access_road?: AccessRoadEnum;

  /** Additional Fees */
  additional_fees?: number;

  /** Pydantic enum type for attic field. */
  attic?: AtticEnum;

  /** Bathroom Count */
  bathroom_count?: number;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Deposit */
  deposit?: number;

  /** Pydantic enum type for fence field. */
  fence?: FenceEnum;

  /** Floors */
  floors?: number;

  /** Garden Size */
  garden_size?: number;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Has Balcony */
  has_balcony?: boolean;

  /** Has Basement */
  has_basement?: boolean;

  /** Has Garage */
  has_garage?: boolean;

  /** Has Terrace */
  has_terrace?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for house_type field. */
  house_type?: HouseTypeEnum;

  /** Interior Height */
  interior_height?: number;

  /** Is Gated Community */
  is_gated_community?: boolean;

  /** Pydantic enum type for kitchen_type field. */
  kitchen_type?: KitchenTypeEnum;

  /** Land Length */
  land_length?: number;

  /** Pydantic enum type for land_shape field. */
  land_shape?: LandShapeEnum;

  /** Pydantic enum type for land_type field. */
  land_type?: LandTypeEnum;

  /** Land Width */
  land_width?: number;

  /** Levels */
  levels?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** Rooms */
  rooms?: number;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Pydantic enum type for windows_material field. */
  windows_material?: WindowsMaterialEnum;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * HouseSale object in the DB.
 */
export interface HouseSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for access_road field. */
  access_road?: AccessRoadEnum;

  /** Pydantic enum type for attic field. */
  attic?: AtticEnum;

  /** Bathroom Count */
  bathroom_count?: number;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for fence field. */
  fence?: FenceEnum;

  /** Floors */
  floors?: number;

  /** Garden Size */
  garden_size?: number;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Has Balcony */
  has_balcony?: boolean;

  /** Has Basement */
  has_basement?: boolean;

  /** Has Garage */
  has_garage?: boolean;

  /** Has Terrace */
  has_terrace?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for house_type field. */
  house_type?: HouseTypeEnum;

  /** Interior Height */
  interior_height?: number;

  /** Is Gated Community */
  is_gated_community?: boolean;

  /** Pydantic enum type for kitchen_type field. */
  kitchen_type?: KitchenTypeEnum;

  /** Land Length */
  land_length?: number;

  /** Pydantic enum type for land_shape field. */
  land_shape?: LandShapeEnum;

  /** Pydantic enum type for land_type field. */
  land_type?: LandTypeEnum;

  /** Land Width */
  land_width?: number;

  /** Levels */
  levels?: number;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** Rooms */
  rooms?: number;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Pydantic enum type for windows_material field. */
  windows_material?: WindowsMaterialEnum;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for house_type field.
 */
export enum HouseTypeEnum {
  VILLA = 'VILLA',
  PALACE = 'PALACE',
  TENEMENT = 'TENEMENT',
  DETACHED = 'DETACHED',
  SEMI_DETACHED = 'SEMI_DETACHED',
  HOLIDAY_COTTAGE = 'HOLIDAY_COTTAGE',
  OTHER = 'OTHER',
}

/**
 * IndustrialBuildingRent object in the DB.
 */
export interface IndustrialBuildingRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for floor_type field. */
  floor_type?: FloorTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * IndustrialBuildingSale object in the DB.
 */
export interface IndustrialBuildingSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for floor_type field. */
  floor_type?: FloorTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * An enumeration.
 */
export enum IsolationLevel {
  READCOMMITTED = 'READ COMMITTED',
  READUNCOMMITTED = 'READ UNCOMMITTED',
  REPEATABLEREAD = 'REPEATABLE READ',
  SERIALIZABLE = 'SERIALIZABLE',
}

/**
 * Pydantic enum type for issuer_type field.
 */
export enum IssuerTypeEnum {
  PRIVATE = 'PRIVATE',
  REAL_ESTATE_AGENCY = 'REAL_ESTATE_AGENCY',
  DEVELOPER = 'DEVELOPER',
}

/**
 * Pydantic enum type for kitchen_type field.
 */
export enum KitchenTypeEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  KITCHENETTE = 'KITCHENETTE',
  OTHER = 'OTHER',
}

/**
 * LandRent object in the DB.
 */
export interface LandRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for access_road field. */
  access_road?: AccessRoadEnum;

  /** Pydantic enum type for fence field. */
  fence?: FenceEnum;

  /** Land Length */
  land_length?: number;

  /** Pydantic enum type for land_shape field. */
  land_shape?: LandShapeEnum;

  /** Pydantic enum type for land_type field. */
  land_type?: LandTypeEnum;

  /** Land Width */
  land_width?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * LandSale object in the DB.
 */
export interface LandSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for access_road field. */
  access_road?: AccessRoadEnum;

  /** Pydantic enum type for fence field. */
  fence?: FenceEnum;

  /** Land Length */
  land_length?: number;

  /** Pydantic enum type for land_shape field. */
  land_shape?: LandShapeEnum;

  /** Pydantic enum type for land_type field. */
  land_type?: LandTypeEnum;

  /** Land Width */
  land_width?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for land_shape field.
 */
export enum LandShapeEnum {
  SQUARE = 'SQUARE',
  IRREGULAR = 'IRREGULAR',
  RECTANGLE = 'RECTANGLE',
  TRAPEZOID = 'TRAPEZOID',
  OTHER = 'OTHER',
  RHOMBUS = 'RHOMBUS',
  TRIANGLE = 'TRIANGLE',
}

/**
 * Pydantic enum type for land_type field.
 */
export enum LandTypeEnum {
  COMMERCIAL = 'COMMERCIAL',
  OTHER_PLOT = 'OTHER_PLOT',
  RECREATIONAL = 'RECREATIONAL',
  SERVICE = 'SERVICE',
  INVESTMENT = 'INVESTMENT',
  CONSTRUCTION = 'CONSTRUCTION',
  AGRICULTURAL = 'AGRICULTURAL',
  AGRICULTURAL_CONSTRUCTION = 'AGRICULTURAL_CONSTRUCTION',
  INDUSTRIAL = 'INDUSTRIAL',
  FOREST = 'FOREST',
}

/**
 * Pydantic enum type for location_type field.
 */
export enum LocationTypeEnum {
  IN_AN_OFFICE_BUILDING = 'IN_AN_OFFICE_BUILDING',
  IN_A_PRIVATE_HOUSE = 'IN_A_PRIVATE_HOUSE',
  SEPARATE_FACILITY = 'SEPARATE_FACILITY',
  IN_A_BLOCK_OF_FLATS = 'IN_A_BLOCK_OF_FLATS',
  IN_A_TENEMENT_HOUSE = 'IN_A_TENEMENT_HOUSE',
  IN_A_COMMERCE_CENTER = 'IN_A_COMMERCE_CENTER',
}

export interface MapOffer {
  /** Location Latitude */
  location_latitude: number;

  /** Location Longitude */
  location_longitude: number;

  /** Id */
  id: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Title */
  title: string;

  /** Price */
  price: number;

  /** Property Size */
  property_size?: number;

  /** Price Currency */
  price_currency: string;

  /** Offer Original Url */
  offer_original_url: string;

  /** Issuer Type */
  issuer_type?:
    | IssuerTypeEnum
    | IssuerTypeEnum[]
    | (IssuerTypeEnum & IssuerTypeEnum[]);

  /** Tag */
  tag: string | string[];

  /** Photos Urls */
  photos_urls: string[];

  /** Contact Number */
  contact_number?: string | string[];
}

/**
 * Pydantic enum type for market_type field.
 */
export enum MarketTypeEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export interface MobileAppVersionResponse {
  /** Min Compatible Version */
  min_compatible_version: string;

  /** Latest Version */
  latest_version: string;
}

export interface NotificationsSubscriptionResponse {
  /**
   * Notifications Subscription Id
   * @format uuid
   */
  notifications_subscription_id: string;

  /**
   * Subscriber Id
   * @format uuid
   */
  subscriber_id: string;

  /** An enumeration. */
  subscription_type: SubscriptionType;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

export interface OfferCountResult {
  /** Total Items */
  total_items: number;
}

export interface OfferDetailsResponse {
  /** Offer */
  offer: any;
  avm?: AVMResult;

  /** Spam Probability */
  spam_probability?: number;

  /** Super Offer */
  super_offer?: any;
  spatial_augmentation?: SpatialAugmentationResponse;

  /** DownloadedImages object in the DB. */
  downloaded_photos_urls?: DownloadedImagesResponse;
}

export interface OfferEsListResponse {
  /** Results */
  results: OfferEsResult[];

  /** Page */
  page: number;

  /** Total Items */
  total_items: number;

  /** Total Pages */
  total_pages: number;

  /** Has Next Page */
  has_next_page: boolean;
}

export interface OfferEsResult {
  /** Offer */
  offer: object;
  status?: OfferStatusResponse;

  offer_collections_count: number;

  /** Comments Count */
  comments_count: number;

  /** Duplicates Count */
  duplicates_count: number;

  /** Spam Probability */
  spam_probability?: number;
  avm?: AVMResult;
}

export interface OfferListResponse {
  /** Results */
  results: OfferResult[];

  /** Page */
  page: number;

  /** Total Items */
  total_items: number;

  /** Total Pages */
  total_pages: number;

  /** Has Next Page */
  has_next_page: boolean;
}

/**
 * Offer object in the DB.
 */
export interface OfferResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

export interface OfferResult {
  /** Offer */
  offer: object;
  status?: OfferStatusResponse;

  offer_collections_count: number;

  /** Comments Count */
  comments_count: number;

  /** Statuses Count */
  statuses_count: number;

  /** Count of Phone Call Summaries **/
  phone_call_summary_count: number;

  /** Duplicates Count */
  duplicates_count: number;

  /** Spam Probability */
  spam_probability?: number;

  sms_messages_count: number;

  /** Avm */
  avm?: AVMResult;

  /** Most Relevant Activity */
  most_relevant_activity: MostRelevantActivity;

  assignment?: OfferAssignmentResponse;
}

export interface MostRelevantActivity {
  offer_status?: MostRelevantStatus | null;
  call_result?: MostRelevantCallResult | null;
}

export interface MostRelevantStatus {
  offer_status: OfferStatus;
  is_archived: boolean;
  is_added_by_user: boolean;
}

export interface MostRelevantCallResult {
  call_result: PhoneCallResultEnum;
  is_added_by_user: boolean;
}

/**
 * An enumeration.
 */
export enum OfferStatus {
  MARKED_FOR_CONTACT = 'MARKED_FOR_CONTACT',
  CLIENT_ACQUIRED = 'CLIENT_ACQUIRED',
  CLIENT_REJECTED = 'CLIENT_REJECTED',
}

export interface OfferStatusDetailsResponse {
  /**
   * Offer Status Id
   * @format uuid
   */
  offer_status_id: string;

  /** An enumeration. */
  offer_status: OfferStatus;

  /**
   * Added At
   * @format date-time
   */
  added_at: string;
  added_by: UserPublicResponse;

  /**
   * Removed At
   * @format date-time
   */
  removed_at?: string;
  removed_by?: UserPublicResponse;

  /**
   * Changed To Id
   * @format uuid
   */
  changed_to_id?: string;

  /**
   * Archived At
   * @format date-time
   */
  archived_at?: string;
  archived_by?: UserPublicResponse;
}

export interface OfferStatusResponse {
  /**
   * Offer Status Id
   * @format uuid
   */
  offer_status_id: string;

  /** An enumeration. */
  offer_status: OfferStatus;

  /**
   * Added At
   * @format date-time
   */
  added_at: string;

  /**
   * Archived At
   * @format date-time
   */
  archived_at?: string;
}

/**
 * Pydantic enum type for offer_type field.
 */
export enum OfferTypeEnum {
  LAND_SALES = 'LAND_SALES',
  LAND_RENTS = 'LAND_RENTS',
  OFFICE_SALES = 'OFFICE_SALES',
  OFFICE_RENTS = 'OFFICE_RENTS',
  INDUSTRIAL_BUILDING_SALES = 'INDUSTRIAL_BUILDING_SALES',
  INDUSTRIAL_BUILDING_RENTS = 'INDUSTRIAL_BUILDING_RENTS',
  WAREHOUSE_SALES = 'WAREHOUSE_SALES',
  WAREHOUSE_RENTS = 'WAREHOUSE_RENTS',
  COMMERCIAL_BUILDING_SALES = 'COMMERCIAL_BUILDING_SALES',
  COMMERCIAL_BUILDING_RENTS = 'COMMERCIAL_BUILDING_RENTS',
  APARTMENT_SALES = 'APARTMENT_SALES',
  APARTMENT_RENTS = 'APARTMENT_RENTS',
  HOUSE_SALES = 'HOUSE_SALES',
  HOUSE_RENTS = 'HOUSE_RENTS',
  GARAGE_SALES = 'GARAGE_SALES',
  GARAGE_RENTS = 'GARAGE_RENTS',
  ROOM_RENTS = 'ROOM_RENTS',
  AUCTIONS = 'AUCTIONS',
}

export interface OfferWithStatusListResponse {
  /** Results */
  results: OfferWithStatusResult[];

  /** Page */
  page: number;

  /** Total Items */
  total_items: number;

  /** Total Pages */
  total_pages: number;

  /** Has Next Page */
  has_next_page: boolean;
}

export interface OfferWithStatusResult {
  /** Offer */
  offer:
    | ApartmentRentResponse
    | ApartmentSaleResponse
    | AuctionResponse
    | CommercialBuildingRentResponse
    | CommercialBuildingSaleResponse
    | GarageRentResponse
    | GarageSaleResponse
    | HouseRentResponse
    | HouseSaleResponse
    | IndustrialBuildingRentResponse
    | IndustrialBuildingSaleResponse
    | LandRentResponse
    | LandSaleResponse
    | OfficeRentResponse
    | OfficeSaleResponse
    | RoomRentResponse
    | WarehouseRentResponse
    | WarehouseSaleResponse
    | OfferResponse
    | (ApartmentRentResponse &
        ApartmentSaleResponse &
        AuctionResponse &
        CommercialBuildingRentResponse &
        CommercialBuildingSaleResponse &
        GarageRentResponse &
        GarageSaleResponse &
        HouseRentResponse &
        HouseSaleResponse &
        IndustrialBuildingRentResponse &
        IndustrialBuildingSaleResponse &
        LandRentResponse &
        LandSaleResponse &
        OfficeRentResponse &
        OfficeSaleResponse &
        RoomRentResponse &
        WarehouseRentResponse &
        WarehouseSaleResponse &
        OfferResponse);
  status: OfferStatusResponse;

  offer_collections_count: number;

  /** Comments Count */
  comments_count: number;

  phone_call_summary_count: number;
  statuses_count: number;

  /** Duplicates Count */
  duplicates_count: number;

  /** Spam Probability */
  spam_probability?: number;
  avm?: AVMResult;

  /** Most Relevant Activity */
  most_relevant_activity: MostRelevantActivity;
}

/**
 * OfficeRent object in the DB.
 */
export interface OfficeRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Office Class */
  office_class?: string;

  /** Parking Lots */
  parking_lots?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * OfficeSale object in the DB.
 */
export interface OfficeSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Office Class */
  office_class?: string;

  /** Parking Lots */
  parking_lots?: number;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

export interface OrganizationInviteRequest {
  /** Email */
  email: string;
}

export interface OrganizationInviteResponse {
  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;

  /** Email */
  email: string;

  /**
   * Organization Invite Id
   * @format uuid
   */
  organization_invite_id: string;
}

/**
 * An enumeration.
 */
export enum OrganizationMemberPrivilege {
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
  BRANCH_DIRECTOR = 'BRANCH_DIRECTOR',
  MODERATOR = 'MODERATOR',
}

export interface OrganizationMemberResponse {
  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;
  user: UserPublicResponse;

  /** An enumeration. */
  privilege: OrganizationMemberPrivilege;
  team?: TeamResponse;
  branch?: BranchResponse;

  is_deactivated: boolean;
}

export interface OrganizationPublicResponse {
  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;

  /** Name */
  name: string;

  logo_url?: string | null;
  website?: string | null;
  address?: string | null;
  phone_number?: string | null;
}

/**
 * An enumeration.
 */
export enum OrganizationType {
  B2C = 'B2C',
  B2B = 'B2B',
}

/**
 * Pydantic enum type for ownership_type field.
 */
export enum OwnershipTypeEnum {
  OWNERSHIP = 'OWNERSHIP',
  PERPETUAL_USUFRUCT = 'PERPETUAL_USUFRUCT',
  COOPERATIVE_OWNERSHIP = 'COOPERATIVE_OWNERSHIP',
  MORTGAGE = 'MORTGAGE',
  SHARE = 'SHARE',
  OTHER = 'OTHER',
}

/**
 * Pydantic enum type for parking field.
 */
export enum ParkingEnum {
  IN_THE_GARAGE = 'IN_THE_GARAGE',
  NONE = 'NONE',
  UNDERGROUND_PARKING_LOT = 'UNDERGROUND_PARKING_LOT',
  PARKING_LOT = 'PARKING_LOT',
  BELONGING_TO_THE_STREET = 'BELONGING_TO_THE_STREET',
}

export interface PhoneCallRequest {
  /** Phone Number */
  phone_number: string;
}

export interface PhoneCallResponse {
  /**
   * Phone Call Id
   * @format uuid
   */
  phone_call_id: string;
  user: UserPublicResponse;

  /** An enumeration. */
  call_type: CallTypeEnum;

  /** Phone Number */
  phone_number: string;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;

  /**
   * Started At
   * @format date-time
   */
  started_at?: string;

  /**
   * Incoming At
   * @format date-time
   */
  incoming_at?: string;

  /**
   * Ended At
   * @format date-time
   */
  ended_at?: string;

  /**
   * Missed At
   * @format date-time
   */
  missed_at?: string;
}

/**
 * An enumeration.
 */
export enum PhoneCallResultEnum {
  CLIENT_CONVERTED = 'CLIENT_CONVERTED',
  CLIENT_BUSY = 'CLIENT_BUSY',
  CALL_LATER = 'CALL_LATER',
  CLIENT_NOT_INTERESTED = 'CLIENT_NOT_INTERESTED',
  NOT_ANSWERED = 'NOT_ANSWERED',
  OFFER_SENT = 'OFFER_SENT',
  COMMISSION_FREE = 'COMMISSION_FREE',
}

export interface PhoneCallSummaryResponse {
  /**
   * Phone Call Summary Id
   * @format uuid
   */
  phone_call_summary_id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;

  /**
   * User Id
   * @format uuid
   */
  user_id: string;

  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;

  /** Phone Number */
  phone_number: string;

  /** Notes */
  notes?: string;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;

  /** An enumeration. */
  result: PhoneCallResultEnum;
}

export interface PromoteOrganizationRequest {
  /** Name */
  name: string;
}

export interface RegisterToOrganizationRequest {
  /** Password */
  password: string;

  /** First Name */
  first_name: string;

  /** Last Name */
  last_name: string;

  phone_number: string;

  /** Organization Invite Id */
  organization_invite_id: string;
}

export interface RegisterUserResponse {
  is_activated: boolean;
}

export interface RequestNumberVerification {
  /** Phone Number */
  phone_number: string;
}

export interface RegisterUserRequest {
  /** Email */
  email: string;

  /** Password */
  password: string;

  /** First Name */
  first_name: string;

  /** Last Name */
  last_name: string;

  /** Phone Number */
  phone_number: string;

  /** Nip */
  nip: string;

  /** Organization Name */
  organization_name: string;

  referral_code?: string | null;
  source?: string | null;
  sms_code: string;
  organization_size: string;
}

export interface ResetPasswordChangeRequest {
  /** Password */
  password: string;

  /**
   * Reset Id
   * @format uuid
   */
  reset_id: string;
}

/**
 * Pydantic enum type for roof_material field.
 */
export enum RoofMaterialEnum {
  BITUMEN_SHINGLE = 'BITUMEN_SHINGLE',
  METAL_SHEET = 'METAL_SHEET',
  METAL_TILE = 'METAL_TILE',
  ROOFING_FELT = 'ROOFING_FELT',
  CERAMIC_TILE = 'CERAMIC_TILE',
  WOOD = 'WOOD',
  OTHER = 'OTHER',
}

/**
 * RoomRent object in the DB.
 */
export interface RoomRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Has Air Conditioning */
  has_air_conditioning?: boolean;

  /** Pydantic enum type for room_type field. */
  room_type?: RoomTypeEnum;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for room_type field.
 */
export enum RoomTypeEnum {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  MULTIPLE = 'MULTIPLE',
}

export interface SavedOfferResponse {
  /**
   * Saved Offer Id
   * @format uuid
   */
  saved_offer_id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/**
 * Pydantic enum type for sewage field.
 */
export enum SewageEnum {
  MUNICIPAL = 'MUNICIPAL',
  CESSPOOL = 'CESSPOOL',
  NONE = 'NONE',
}

export interface SpatialAugmentationResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Tram */
  tram: object;

  /** Subway */
  subway: object;

  /** Bus */
  bus: object;
}

/**
 * An enumeration.
 */
export enum SubscriptionType {
  LIVE = 'LIVE',
  DAILY = 'DAILY',
}

export interface UpdateCallListRequest {
  /** Positions */
  positions: object;

  /** An enumeration. */
  call_list_type: CallListType;
}

export interface UpdateUserSettingsRequest {
  /** First Name */
  first_name: string;

  /** Last Name */
  last_name: string;

  /** Email */
  email: string;

  /** Settings */
  settings: object;

  /** Phone Number */
  phone_number?: string | null;

  job_title?: string | null;
}

export interface UpdateViewRequest {
  /** Title */
  title: string;

  /** Sorts */
  sorts: object[];

  /** Filters */
  filters: object[];

  /** Offer types */
  offer_types: OfferTypeEnum[];

  /** Description */
  description?: string;

  /** An enumeration. */
  subscription_type?: SubscriptionType;
}

export interface UserOrganizationResponse {
  /**
   * Organization Id
   * @format uuid
   */
  organization_id: string;

  /** Name */
  name: string;

  /** An enumeration. */
  type: OrganizationType;

  logo_url?: string;

  website?: string;

  address?: string;

  phone_number?: string;
  legal_name?: string | null;
}

export interface UserPrivateResponse {
  /**
   * User Id
   * @format uuid
   */
  user_id: string;

  /** First Name */
  first_name: string;

  /** Last Name */
  last_name: string;

  /** Email */
  email: string;

  /** Settings */
  settings: object;

  /** Phone Number */
  phone_number?: string;
  selected_organization: UserOrganizationResponse;

  /** Avatar Url */
  avatar_url?: string;

  /** Performed Actions */
  performed_actions?: string[];

  enabled_feature_flags: FeatureFlag[];
  job_title?: string;
  organization_member_privilege: OrganizationMemberPrivilege;
  team: TeamResponse | null;
  branch: BranchResponse | null;
  app_subscription: {
    subscription_tier: SubscriptionTierType;
    expires_at: string;
    users_limit: number;
  };
  communication_consents: {
    is_app_consent_given: boolean;
    is_marketing_consent_given: boolean;
  } | null;
}

export interface UserPublicResponse {
  /** First Name */
  first_name: string;

  /** Last Name */
  last_name: string;

  /** Email */
  email: string;

  /**
   * User Id
   * @format uuid
   */
  user_id: string;

  /** Avatar Url */
  avatar_url?: string;

  /** Phone Number */
  phone_number?: string;

  job_title?: string;
}

export interface ValidationError {
  /** Location */
  loc: string[];

  /** Message */
  msg: string;

  /** Error Type */
  type: string;
}

export interface ViewListResponse {
  /** Results */
  results: ViewResponse[];

  /** Page */
  page: number;

  /** Total Items */
  total_items: number;

  /** Total Pages */
  total_pages: number;

  /** Has Next Page */
  has_next_page: boolean;
}

export interface CreateViewResponse {
  view_id: string;
}

export interface ViewResponse {
  /**
   * View Id
   * @format uuid
   */
  view_id: string;

  /** Title */
  title: string;

  /** Description */
  description?: string;

  view_metadata?: object;

  /** Offer types */
  offer_types: OfferTypeEnum[];

  /** Sorts */
  sorts: object[];

  /** Filters */
  filters: object[];

  /**
   * Author Id
   * @format uuid
   */
  author_id: string;
  notifications_subscription?: NotificationsSubscriptionResponse;
}

/**
 * WarehouseRent object in the DB.
 */
export interface WarehouseRentResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for floor_type field. */
  floor_type?: FloorTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * WarehouseSale object in the DB.
 */
export interface WarehouseSaleResponse {
  /**
   * Id
   * @format uuid
   */
  id: string;

  /** Pydantic enum type for building_material field. */
  building_material?: BuildingMaterialEnum;

  /** Pydantic enum type for building_stage field. */
  building_stage?: BuildingStageEnum;

  /** Pydantic enum type for floor_type field. */
  floor_type?: FloorTypeEnum;

  /** Has Anti Theft Protection */
  has_anti_theft_protection?: boolean;

  /** Pydantic enum type for heating field. */
  heating?: HeatingEnum;

  /** Pydantic enum type for market_type field. */
  market_type?: MarketTypeEnum;

  /** Parking Lots */
  parking_lots?: number;

  /**
   * Planned Completion
   * @format date
   */
  planned_completion?: string;

  /** Pydantic enum type for roof_material field. */
  roof_material?: RoofMaterialEnum;

  /** To Be Renovated */
  to_be_renovated?: boolean;

  /** Year Built */
  year_built?: number;

  /** Address City */
  address_city: string;

  /** Address Community */
  address_community: string;

  /** Address Country */
  address_country: string;

  /** Address County */
  address_county: string;

  /** Address District */
  address_district?: string;

  /** Address Postal Code */
  address_postal_code?: string;

  /** Address Raw */
  address_raw: string;

  /** Address State */
  address_state: string;

  /** Address Street */
  address_street?: string;

  /** Address Street Number */
  address_street_number?: string;

  /** Agency Offer Id */
  agency_offer_id?: string;

  /** Changes History */
  changes_history: object;

  /** Contact Number */
  contact_number?: string;

  /**
   * Date Last Seen
   * @format date-time
   */
  date_last_seen: string;

  /**
   * Date Modified
   * @format date-time
   */
  date_modified?: string;

  /**
   * Date Posted
   * @format date-time
   */
  date_posted?: string;

  /**
   * Date Scraped
   * @format date-time
   */
  date_scraped: string;

  /** Description */
  description: string;

  /** Description Clean Text */
  description_clean_text?: string;

  /** Has Cable Internet Access */
  has_cable_internet_access?: boolean;

  /** Has Cable Telephone Access */
  has_cable_telephone_access?: boolean;

  /** Has Cable Television Access */
  has_cable_television_access?: boolean;

  /** Has Electricity Access */
  has_electricity_access?: boolean;

  /** Has Gas Access */
  has_gas_access?: boolean;

  /** Has High Voltage Plug */
  has_high_voltage_plug?: boolean;

  /** Has Land And Mortgage Register */
  has_land_and_mortgage_register?: boolean;

  /** Pydantic enum type for heated_water field. */
  heated_water?: HeatedWaterEnum;

  /** Is Premium Offer */
  is_premium_offer?: boolean;

  /** Pydantic enum type for issuer_type field. */
  issuer_type?: IssuerTypeEnum;

  /** Location Approximation */
  location_approximation?: number;

  /** Location Latitude */
  location_latitude?: number;

  /** Location Longitude */
  location_longitude?: number;

  /** Offer Original Url */
  offer_original_url: string;

  /** Pydantic enum type for offer_type field. */
  offer_type: OfferTypeEnum;

  /** Pydantic enum type for ownership_type field. */
  ownership_type?: OwnershipTypeEnum;

  /** Photos Urls */
  photos_urls: string[];

  /** Portal Offer Id */
  portal_offer_id: string;

  /** Price */
  price: number;

  /** Price Currency */
  price_currency?: string;

  /** Price Per Meter */
  price_per_meter?: number;

  /** Property Size */
  property_size?: number;

  /** Pydantic enum type for sewage field. */
  sewage?: SewageEnum;

  /** Tag */
  tag: string;

  /** Title */
  title: string;

  /** View Count */
  view_count?: number;

  /** Pydantic enum type for water_access field. */
  water_access?: WaterAccessEnum;
}

/**
 * Pydantic enum type for water_access field.
 */
export enum WaterAccessEnum {
  WATER_SUPPLY = 'WATER_SUPPLY',
  WELL = 'WELL',
  NONE = 'NONE',
}

/**
 * Pydantic enum type for windows_material field.
 */
export enum WindowsMaterialEnum {
  PLASTIC = 'PLASTIC',
  ALUMINUM = 'ALUMINUM',
  WOOD = 'WOOD',
}

export enum WatermarkRemovalLROStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCEED = 'SUCCEED',
  PARTIALLY_SUCCEED = 'PARTIALLY_SUCCEED',
}

export interface WatermarkRemovalRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface WatermarkRemovalImage {
  original_photo_url: string;
  rectangles: WatermarkRemovalRectangle[];
}

export interface PostWatermarkRemovalLroRequest {
  offer_variant_id: string;
  images: WatermarkRemovalImage[];
}

export interface PostWatermarkRemovalLroResponse {
  lro_id: string;
}

export interface GetWatermarkRemovalLroBase {
  lro_id: string;
  organization_id: string;
  offer_variant_id: string;
  status: WatermarkRemovalLROStatus;
  error_code?: string;
}

export interface GetWatermarkRemovalLroResponse
  extends GetWatermarkRemovalLroBase {
  images: WatermarkRemovalImage[];
}

export interface GetWatermarkRemovalQuota {
  used: number;
  limit: number;
}
