export const APP_URL = 'https://propertly.io';

export const ANALYTICS_URL = 'https://analytics.propertly.io';
export const APP_PRICING_URL = `${APP_URL}/#pricing`;
export const APP_CONTACT_URL = `${APP_URL}/#contact`;
export const CENAMETRA_URL = 'https://cenametra.pl';

export const FACEBOOK_URL = 'https://www.facebook.com/propertly.io';
export const INSTAGRAM_URL = 'https://www.instagram.com/propertly.io';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/propertly-io';
