import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { postRegisterToOrganization } from '../../api/auth/auth';
import TextInputLabeledController from '../../components/inputs/text-input/text-input-labeled-controller';
import { RegisterToOrganizationRequest } from '../../api/api.types';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/button/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import SuccessToast from '../../components/toasts/success-toast';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import logo from '../../assets/logo-transparent.png';
import bg from '../../assets/sign-up-to-organization-bg.jpg';
import CheckboxInputLabeledController from '../../components/inputs/checkbox-input/checkbox-input-labeled-controller';
import { TERMS_URL } from '../../constants/external';
import { RegisterErrors } from '../sign-up/sign-up';
import { useParamsRequired } from '../../hooks/use-params-required/use-params-required';
import { getOrganizationByInviteId } from '../../api/organizations/organizations.api';
import Spinner from '../../components/spinner/spinner';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS, SignUpMethods } from '../../constants/analytics';
import PageHeader from '../../components/header/page-header';
import { useAuth } from '../../context/auth/use-auth';
import { isPhone } from '../../constants/validation';

enum OrganizationInviteRegisterErrors {
  INVALID_ORGANIZATION_INVITE = 'INVALID_ORGANIZATION_INVITE',
}

const SignUpToOrganization = () => {
  const { t } = useTranslation(['auth', 'common']);
  const { loadUser } = useAuth();
  const navigate = useNavigate();
  const { inviteId } = useParamsRequired<{ inviteId: string }>();
  const analytics = useAnalytics();
  const { data, isLoading: isInviteLoading } = useQuery(
    ['signUpToOrganization', inviteId],
    () => getOrganizationByInviteId(inviteId),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          toast.custom((toast) => (
            <ErrorToast
              {...toast}
              title={t(
                'auth:alerts.registerUserInvalidOrganizationInvite.title',
              )}
              body={t('auth:alerts.registerUserInvalidOrganizationInvite.body')}
            />
          ));
          navigate('/dashboard');
          return;
        }

        defaultErrorToasts(error, t);
      },
    },
  );

  const schema = React.useMemo(() => {
    return yup.object({
      password: yup
        .string()
        .min(6, t('auth:fieldErrors.mustBeAtLeast6CharsLong')),
      first_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      last_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      phone_number: isPhone(t, { required: true }),
      accept: yup
        .boolean()
        .isTrue(t('auth:fieldErrors.mustBeAccepted'))
        .required(t('auth:fieldErrors.mustBeAccepted')),
    });
  }, [t]);

  const { control, handleSubmit } = useForm<
    RegisterToOrganizationRequest & { accept: boolean }
  >({
    defaultValues: {
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      accept: false,
    },
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(postRegisterToOrganization, {
    onSuccess: async () => {
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('auth:alerts.signUpToOrganizationSuccessful.title')}
          body={t('auth:alerts.signUpToOrganizationSuccessful.body')}
        />
      ));
      await loadUser();
      await analytics.track(ANALYTICS_EVENTS.USER_REGISTERED, {
        method: SignUpMethods.ORGANIZATION,
      });
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;

      if (data.detail === RegisterErrors.REGISTER_USER_ALREADY_EXISTS) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.registerUserAlreadyExists.title')}
            body={t('auth:alerts.registerUserAlreadyExists.body')}
          />
        ));
        return;
      }

      if (
        data.detail ===
        OrganizationInviteRegisterErrors.INVALID_ORGANIZATION_INVITE
      ) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.registerUserInvalidOrganizationInvite.title')}
            body={t('auth:alerts.registerUserInvalidOrganizationInvite.body')}
          />
        ));
        return;
      }

      defaultErrorToasts(e, t);
    },
  });

  const onSubmit = (
    form: RegisterToOrganizationRequest & { accept: boolean },
  ) => {
    const { accept, phone_number, ...rest } = form;
    mutate({
      ...rest,
      phone_number: `+48${phone_number}`,
      organization_invite_id: inviteId,
    });
  };

  if (isInviteLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-full flex-row-reverse bg-gray-50">
      <PageHeader title={t('common:pageTitle.signUpToOrganization')} />
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="w-full max-w-md space-y-8">
          <div className="mx-4 rounded-lg border border-gray-100 bg-white p-1 shadow-lg">
            <div className="p-6">
              <div className="flex items-center justify-center">
                <img src={logo} alt="Logo" className="h-10" />
              </div>
              <h2 className="mt-4 text-center text-xl font-medium text-gray-900">
                {t('auth:signUpToOrganization.header')}{' '}
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                <Trans
                  i18nKey="auth:signUpToOrganization.description"
                  t={t}
                  values={{
                    organization: data?.name ?? '',
                  }}
                  components={{
                    organizationWrapper: <span className="text-indigo-600" />,
                  }}
                />
              </p>
              <form
                className="mt-6 space-y-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <TextInputLabeledController
                  name="first_name"
                  control={control}
                  id="first-name"
                  autoComplete="first-name"
                  labelClassName="mb-1"
                  label={t('auth:fields.firstName')}
                />
                <TextInputLabeledController
                  name="last_name"
                  control={control}
                  id="last-name"
                  autoComplete="last-name"
                  labelClassName="mb-1"
                  label={t('auth:fields.lastName')}
                />
                <TextInputLabeledController
                  name="phone_number"
                  control={control}
                  id="phone-number"
                  prefixIcon={<div className="sm:text-sm">+48</div>}
                  autoComplete="phone"
                  label={t('auth:fields.phoneNumber')}
                />
                <PasswordInputLabeledController
                  name="password"
                  control={control}
                  id="password"
                  autoComplete="password"
                  labelClassName="mb-1"
                  label={t('auth:fields.password')}
                />
                <div className="flex flex-row">
                  <CheckboxInputLabeledController
                    name="accept"
                    control={control}
                    id="accept"
                    changeOnLabelClick={false}
                    label={
                      (
                        <div>
                          {t('auth:fields.accept')}{' '}
                          <a
                            href={TERMS_URL}
                            target="_blank"
                            rel="noreferrer"
                            className="text-indigo-600 hover:text-indigo-500"
                          >
                            {t('auth:fields.terms')}
                          </a>
                        </div>
                      ) as any
                    }
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    className="flex w-full justify-center px-4 py-2"
                  >
                    {t('auth:signUp.signUp')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default SignUpToOrganization;
