import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../context/auth/use-user';
import { usePropagateParams } from '../../hooks/use-propagate-params/use-propagate-params';

interface Props {
  toWhenAuth: string;
  toWhenNotAuth: string;
}

const AuthRedirect = ({ toWhenAuth, toWhenNotAuth }: Props) => {
  const user = useUser();
  const withParams = usePropagateParams();

  return user.isAuthenticated ? (
    <Navigate to={toWhenAuth} replace />
  ) : (
    <Navigate to={withParams(toWhenNotAuth)} replace />
  );
};

export default AuthRedirect;
