import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_SETTINGS } from '../models/user-settings';

i18n
  .use({
    type: 'backend',
    read(
      language: any,
      namespace: any,
      callback: (errorValue: any, translations: any) => void,
    ) {
      import(`./locale/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  })
  .use(initReactI18next)
  .init({
    ns: [
      'analytics',
      'auth',
      'call-list',
      'common',
      'data-model',
      'lang',
      'navigation',
      'offers',
      'organizations',
      'offer-collections',
      'settings',
      'views',
      'views-builder',
      'forms',
      'outreach',
      'client-searches',
      'monitoring',
      'validation',
      'meetings',
      'lead',
    ],
    lng: DEFAULT_SETTINGS.language,
    interpolation: {
      escapeValue: false,
    },
  });

document.documentElement.setAttribute('lang', DEFAULT_SETTINGS.language);

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
