import { LoginForm } from '../../pages/login/login';
import {
  ChangeUserPasswordRequest,
  ForgotPasswordRequest,
  RegisterToOrganizationRequest,
  RegisterUserResponse,
  RequestNumberVerification,
  ResetPasswordChangeRequest,
  UpdateUserConsentsRequest,
  UpdateUserSettingsRequest,
  UserPrivateResponse,
} from '../api.types';
import { axios } from '../axios';
import { RegisterUserForm } from '../../pages/sign-up/sign-up';

export function postLogin(form: LoginForm): Promise<void> {
  const formData = new FormData();
  formData.set('username', form.email);
  formData.set('password', form.password);
  if (form.remember) {
    formData.set('scope', 'refresh');
  }
  return axios
    .post<void>('/login', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((resp) => resp.data);
}

export function postRegister(
  form: RegisterUserForm,
): Promise<RegisterUserResponse> {
  const { remember, ...rest } = form;
  return axios
    .post<RegisterUserResponse>('/register', {
      ...rest,
    })
    .then((resp) => resp.data);
}

export function postRequestNumberVerification(
  body: RequestNumberVerification,
): Promise<RegisterUserResponse> {
  return axios
    .post<RegisterUserResponse>('/number-verification', body)
    .then((resp) => resp.data);
}

export function postRegisterToOrganization(
  body: RegisterToOrganizationRequest,
): Promise<void> {
  return axios
    .post<void>('/register-to-organization', body)
    .then((resp) => resp.data);
}

export function postLogout(): Promise<void> {
  return axios.post<void>('/logout').then((resp) => resp.data);
}

export function getMe(): Promise<UserPrivateResponse> {
  return axios.get<UserPrivateResponse>('/me').then((resp) => resp.data);
}

export function refreshToken(): Promise<void> {
  return axios.post<void>('/refresh').then((resp) => resp.data);
}

export function putSettings(
  settings: UpdateUserSettingsRequest,
): Promise<void> {
  return axios.put<void>('/me/settings', settings).then((resp) => resp.data);
}

export function postActivate(activateId: string): Promise<void> {
  return axios.post<void>(`/activate/${activateId}`).then((resp) => resp.data);
}

export function postResetPassword(body: ForgotPasswordRequest): Promise<void> {
  return axios
    .post<void>('/forgot-password/reset', body)
    .then((resp) => resp.data);
}

export function putConsents(body: UpdateUserConsentsRequest): Promise<void> {
  return axios.put('/me/consents', body).then((resp) => resp.data);
}

export function postResetPasswordChange(
  body: ResetPasswordChangeRequest,
): Promise<void> {
  return axios
    .post<void>('/forgot-password/change', body)
    .then((resp) => resp.data);
}

export function postChangePassword(body: ChangeUserPasswordRequest) {
  return axios
    .post<void>('/me/change-password', body)
    .then((resp) => resp.data);
}
