import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Button from '../../components/button/button';
import { useParamsRequired } from '../../hooks/use-params-required/use-params-required';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { postResetPasswordChange } from '../../api/auth/auth';
import { toast } from 'react-hot-toast';
import SuccessToast from '../../components/toasts/success-toast';
import { AxiosError } from 'axios';
import ErrorToast from '../../components/toasts/error-toast';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import resetPassword from '../../assets/reset-password-bg.png';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import PageHeader from '../../components/header/page-header';
import logo from '../../assets/logo-transparent.png';

interface PasswordResetForm {
  password: string;
}

enum ResetPasswordChangeErrors {
  PASSWORD_RESET_EXPIRED_OR_INVALID = 'PASSWORD_RESET_EXPIRED_OR_INVALID',
}

const ForgotPasswordChange = () => {
  const { t } = useTranslation(['auth', 'common']);
  const schema = React.useMemo(() => {
    return yup.object({
      password: yup
        .string()
        .min(6, t('auth:fieldErrors.mustBeAtLeast6CharsLong')),
    });
  }, [t]);
  const { resetPasswordId } = useParamsRequired<{ resetPasswordId: string }>();
  const { control, handleSubmit, reset } = useForm<PasswordResetForm>({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
  });
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(postResetPasswordChange, {
    onSuccess: async (_, data) => {
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('auth:alerts.passwordResetChangeSuccessful.title')}
          body={t('auth:alerts.passwordResetChangeSuccessful.body')}
        />
      ));
      navigate('/login');
      await analytics.track(ANALYTICS_EVENTS.FORGOT_PASSWORD, {
        resetId: data.reset_id,
      });
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;
      const status = e.response?.status;
      if (
        status === 422 ||
        data?.detail ===
          ResetPasswordChangeErrors.PASSWORD_RESET_EXPIRED_OR_INVALID
      ) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.passwordResetExpiredOrInvalid.title')}
            body={t('auth:alerts.passwordResetExpiredOrInvalid.body')}
          />
        ));
      } else {
        defaultErrorToasts(e, t);
      }
      reset();
    },
  });

  const onSubmit = async (form: PasswordResetForm) => {
    mutate({ ...form, reset_id: resetPasswordId });
  };

  return (
    <div className="flex h-full flex-row bg-gray-50">
      <PageHeader title={t('common:pageTitle.resetPassword')} />
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="w-full max-w-md space-y-8">
          <div className="mx-4 rounded-lg border border-gray-100 bg-white p-1 shadow-lg">
            <div className="px-6 pt-6">
              <div className="flex items-center justify-center">
                <img src={logo} alt="Logo" className="h-10" />
              </div>
              <h2 className="mt-4 text-center text-xl font-medium text-gray-900">
                {t('auth:resetPassword.header')}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                {t('auth:resetPassword.instructions')}
              </p>
              <form
                className="mt-8 space-y-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <PasswordInputLabeledController
                  name="password"
                  control={control}
                  id="password"
                  autoComplete="password"
                  labelClassName="mb-1"
                  label={t('auth:fields.newPassword')}
                />
                <Button
                  type="submit"
                  className="w-full px-4 py-2"
                  isLoading={isLoading}
                >
                  {t('auth:resetPassword.confirm')}
                </Button>
              </form>
            </div>
            <div className="mt-6 bg-gray-50 px-6 py-4 text-center">
              <p className="ml-auto mt-2 text-sm text-gray-600">
                {t('auth:resetPassword.continueWithPrevious')}{' '}
                <Link
                  to="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {t('auth:resetPassword.login')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={resetPassword}
          alt="Reset password"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordChange;
