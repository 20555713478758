import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bg from '../../assets/sign-up-bg.jpg';
import PageHeader from '../../components/header/page-header';
import logo from '../../assets/logo-transparent.png';
import MessageSentSvg from '../../assets/svg/message-sent-svg';

const AccountCreated = () => {
  const { t } = useTranslation(['auth', 'common']);

  return (
    <div className="flex h-full flex-row bg-gray-50">
      <PageHeader title={t('common:pageTitle.accountCreated')} />
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="w-full max-w-md space-y-8">
          <div className="mx-4 rounded-lg border border-gray-100 bg-white p-1 shadow-lg">
            <div className="px-6 pt-6">
              <div className="flex items-center justify-center">
                <img src={logo} alt="Logo" className="h-10" />
              </div>
              <h2 className="mt-4 text-center text-xl font-medium text-gray-900">
                {t('auth:activateAccount.header')}
              </h2>
              <p className="mt-4 text-center text-sm text-gray-600">
                {t('auth:activateAccount.description')}
              </p>
              <MessageSentSvg className="my-6 flex h-80 w-full" />
            </div>
            <div className="mt-6 bg-gray-50 px-6 py-4 text-center">
              <p className="ml-auto mt-2 text-sm text-gray-600">
                {t('auth:activateAccount.alreadyActive')}{' '}
                <Link
                  to="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {t('auth:activateAccount.login')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default AccountCreated;
