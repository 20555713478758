interface Props {
  mainColor?: string;
  className?: string;
}

const MessageSentSvg = ({ mainColor = '#4f46e5', className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="674.81823"
      height="590.59868"
      viewBox="0 0 674.81823 590.59868"
      role="img"
      className={className}
    >
      <path
        d="M295.15237,355.9586l-32.53806-18.32671a120.49146,120.49146,0,0,1,12.68417-56.56929c7.5018,34.04891,46.98122,50.24832,65.19652,79.97724a72.47584,72.47584,0,0,1,5.88427,62.54382l2.14,26.39926a121.44657,121.44657,0,0,1-76.24937-67.27817,117.31036,117.31036,0,0,1-8.13843-28.28193C279.30211,356.03721,295.15237,355.9586,295.15237,355.9586Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#f2f2f2"
      />
      <path
        d="M880.07789,689.37078l.99775-22.43416a72.4554,72.4554,0,0,1,33.79562-8.555c-16.23146,13.27042-14.203,38.85123-25.20757,56.6968a43.58207,43.58207,0,0,1-31.95921,20.13989l-13.58307,8.31649A73.02986,73.02986,0,0,1,859.51425,684.356a70.543,70.543,0,0,1,12.96441-12.04606C875.73183,680.8879,880.07789,689.37078,880.07789,689.37078Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#f2f2f2"
      />
      <polygon
        points="582.489 577.289 570.189 577.288 564.338 529.848 582.491 529.849 582.489 577.289"
        fill="#ffb6b6"
      />
      <path
        d="M848.2162,743.91183l-39.658-.00147v-.50161a15.43685,15.43685,0,0,1,15.436-15.43577h.001l7.24407-5.49573,13.51581,5.49657,3.46191.00014Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#2f2e41"
      />
      <polygon
        points="525.348 577.289 513.049 577.288 507.198 529.848 525.351 529.849 525.348 577.289"
        fill="#ffb6b6"
      />
      <path
        d="M791.07593,743.91183l-39.658-.00147v-.50161a15.43685,15.43685,0,0,1,15.436-15.43577h.001l7.24407-5.49573,13.51581,5.49657,3.4619.00014Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#2f2e41"
      />
      <path
        d="M782.6155,472.63086l80,2c15.19373,8.25247,15.42444,26.11377,10.44617,47.32025,0,0,1.68058,12.60446-1.68061,15.96565s-5.04179,3.36119-3.36119,9.24328-7.19878,15.48928-6.12029,16.56777,3.59939,6.96058,3.59939,6.96058l-8.403,47.897s-5.88209,83.18952-7.56268,84.87011-3.36119,0-1.6806,4.20149,3.36119,2.5209,1.6806,4.20149a61.88778,61.88778,0,0,0-4.20149,5.04179h-20.316s-1.53172-8.403-1.53172-9.24328-1.6806-5.88208-1.6806-6.72238,1.48541-2.32571,1.48541-2.32571a27.6452,27.6452,0,0,0,1.03548-5.237c0-1.68059,5.04179-66.38355,5.04179-66.38355l-10.08357-84.02981-24.36865,82.34922s0,72.26563-1.6806,73.94623-1.68059.8403-.84029,4.20149,4.20149,2.5209,1.68059,5.04179-3.36119-1.6806-2.52089,2.52089l.8403,4.2015-25.209.3606s-3.36119-7.083-1.68059-9.60388,1.57976-1.83278-.47057-5.95818-2.89063-4.9657-2.05033-5.806.8403-5.30976.8403-5.30976l4.20149-80.40065s.8403-84.87011.8403-87.391a8.38157,8.38157,0,0,0-.698-3.7803v-3.42178l3.21893-12.12478Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#2f2e41"
      />
      <circle cx="568.08172" cy="126.72635" r="24.85645" fill="#ffb6b6" />
      <path
        d="M818.24437,322.71185c-5.355,3.18356-8.55835,9.03293-10.39722,14.98517a142.00821,142.00821,0,0,0-6.10242,33.92892l-1.94226,34.47532-24.05462,73.40765c20.84735,17.64,99.20941,4.00906,99.20941,4.00906s2.40545-.80179,0-3.20727-4.74722-.27435-2.34176-2.67984.74721.27435-.0546-2.13114,0-.80178.80181-1.60363-6.20727-8.01825-6.20727-8.01825L873.57,423.74118l8.01819-84.993c-9.62189-12.02732-28.97268-19.11734-28.97268-19.11734l-5-9-25,2Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e6e6e6"
      />
      <path
        d="M814.83523,266.83935a11.74568,11.74568,0,0,1,2.85009.374,40.56522,40.56522,0,0,0,5.57911.93554,25.8518,25.8518,0,0,1,23.14794,21.92774c1.44434-1.25928,4.731-3.57568,7.42481-1.42285h0c.041.03418.06494.05176.15967.00683,1.78906-.84863,4.96484-11.29492,5.061-20.49267.05078-4.88184-.71777-11.11572-4.666-13.08985l-.208-.104-.05469-.22607c-.26367-1.09815-3.21094-2.97266-8.333-4.43164-9.30859-2.65137-23.18418-7.81629-30.99766-.21521-.5913,2.5625-2.515,8.04626-4.45839,8.66784-2.15186.688-4.01026,1.28174-4.30322,3.92139a80.90954,80.90954,0,0,0,.05322,11.2793,11.51926,11.51926,0,0,1,4.09228-5.74854A8.11106,8.11106,0,0,1,814.83523,266.83935Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#2f2e41"
      />
      <path
        d="M768.57014,404.1821l40.60315-26.00339-20.4505-27.18528-20.19359,24.84-62.91881,3.85893a11.99113,11.99113,0,1,0-.67121,17.67065Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#ffb6b6"
      />
      <path
        d="M846.85849,341.88316c2.35656,14.26167-42.14558,41.49536-42.14558,41.49536-.0007-3.34743-26.56183,21.92349-27.76074,19.14726-3.40742-7.8903-5.80313-26.57884-10.56448-29.57011-2.72294-1.71066,16.39926-17.78078,16.39926-17.78078s10.00953-10.08383,23.14493-22.80307a30.64421,30.64421,0,0,1,28.38206-8.29616S844.50191,327.62151,846.85849,341.88316Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e6e6e6"
      />
      <path
        d="M930.54809,411.00762l-26.8573-40.04345-26.74616,21.02154,25.26206,19.66309,5.19033,62.823a11.99111,11.99111,0,1,0,17.68089.29689Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#ffb6b6"
      />
      <path
        d="M866.60542,334.056c14.20857-2.658,42.37846,41.2575,42.37846,41.2575-3.34667.07157,22.481,26.09165,19.73078,27.34908-7.81638,3.57373-26.45,6.36462-29.33979,11.18824-1.65262,2.75855-18.124-16.01909-18.124-16.01909s-10.29352-9.79376-23.288-22.6569a30.64421,30.64421,0,0,1-8.89528-28.2S852.39687,336.714,866.60542,334.056Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e6e6e6"
      />
      <path
        d="M937.40912,744.10934a1.18647,1.18647,0,0,1-1.19007,1.19h-280.29a1.19,1.19,0,1,1,0-2.38h280.29A1.18651,1.18651,0,0,1,937.40912,744.10934Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#ccc"
      />
      <path
        d="M677.75271,383.49276H337.58265a20.06844,20.06844,0,0,0-20.0458,20.04579V615.32674a20.06862,20.06862,0,0,0,20.0458,20.04579H677.75243a20.0685,20.0685,0,0,0,20.04607-20.04579V403.53855A20.06826,20.06826,0,0,0,677.75271,383.49276Z"
        transform="translate(-262.59088 -154.70066)"
        fill={mainColor}
      />
      <path
        d="M677.75271,383.49276H337.58265a20.06844,20.06844,0,0,0-20.0458,20.04579V615.32674a20.06862,20.06862,0,0,0,20.0458,20.04579H677.75243a20.0685,20.0685,0,0,0,20.04607-20.04579V403.53855A20.06826,20.06826,0,0,0,677.75271,383.49276Zm6.57844,152.13011A82.40625,82.40625,0,0,1,603.3127,617.7066c-34.51645.44931,19.0976.62451-22.53172.62451-95.19269,0-194.45228-.90963-231.48716-1.49943A18.67483,18.67483,0,0,1,331.00421,598.233V403.55426a6.60062,6.60062,0,0,1,6.57706-6.59332H677.73893a6.59993,6.59993,0,0,1,6.59222,6.57569Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#3f3d56"
      />
      <path
        d="M635.87829,261.53846H376.70447a11.139,11.139,0,0,0-10.471,7.38381l-40.02762,112.2998a11.11665,11.11665,0,0,0,10.45539,14.84872l347.59461.61689h.01548a11.11649,11.11649,0,0,0,10.21729-15.4952l-.60607.25947.60607-.25947-48.393-112.91669A11.10292,11.10292,0,0,0,635.87829,261.53846Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#3f3d56"
      />
      <path
        d="M695.38853,383.63116a11.14573,11.14573,0,0,1-.9,4.38l-48.39,86.92a11.14705,11.14705,0,0,1-10.22,6.73h-259.17a11.14664,11.14664,0,0,1-10.48-7.38l-40.02-86.3a11.10932,11.10932,0,0,1,6.74-14.2,10.93694,10.93694,0,0,1,3.71-.65l42.46-.07995,17.33-.03,228.04-.4,17.34-.03,42.43-.08h.01A11.12186,11.12186,0,0,1,695.38853,383.63116Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#3f3d56"
      />
      <path
        d="M363.08446,542.35835c5.13935,0,75.714,4.31317,75.714,9.45252s-70.57469,9.15868-75.714,9.15868a9.3056,9.3056,0,1,1,0-18.6112Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#3f3d56"
      />
      <path
        d="M619.73851,193.22119h-218.53a22.11557,22.11557,0,0,0-22.09,22.09v266.35h256.76a10.9775,10.9775,0,0,0,5.95-1.73v-264.62A22.11562,22.11562,0,0,0,619.73851,193.22119Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e6e6e6"
      />
      <path
        d="M536.32853,211.13116h-119.76a20.13965,20.13965,0,0,0-20.12,20.11005v250.42h228.04v-182.37A88.26188,88.26188,0,0,0,536.32853,211.13116Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#fff"
      />
      <path
        d="M585.63853,459.4212h-150.34a4.9,4.9,0,1,0,0,9.8h150.34a4.9,4.9,0,1,0,0-9.8Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M585.63853,440.0412h-150.34a4.905,4.905,0,0,0,0,9.81h150.34a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M500.46849,420.6712h-65.17a4.9,4.9,0,1,0,0,9.8h65.17a4.9,4.9,0,1,0,0-9.8Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M585.63853,380.09119h-150.34a4.905,4.905,0,0,0,0,9.81h150.34a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M585.63853,360.72119h-150.34a4.9,4.9,0,1,0,0,9.8h150.34a4.9,4.9,0,1,0,0-9.8Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M500.46849,341.34119h-65.17a4.905,4.905,0,0,0,0,9.81h65.17a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#e4e4e4"
      />
      <path
        d="M585.63853,300.76117h-150.34a4.905,4.905,0,0,0,0,9.81h150.34a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill={mainColor}
      />
      <path
        d="M585.63853,281.39117h-150.34a4.905,4.905,0,0,0,0,9.81h150.34a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill={mainColor}
      />
      <path
        d="M500.46849,262.01117h-65.17a4.905,4.905,0,0,0,0,9.81h65.17a4.905,4.905,0,1,0,0-9.81Z"
        transform="translate(-262.59088 -154.70066)"
        fill={mainColor}
      />
      <circle cx="371.0448" cy="38.16281" r="38.16282" fill={mainColor} />
      <path
        d="M628.42948,212.72544a4.24383,4.24383,0,0,1-3.39572-1.69838l-10.4114-13.8821a4.245,4.245,0,1,1,6.79214-5.09376l6.8115,9.0813,17.49444-26.24132a4.24516,4.24516,0,1,1,7.06438,4.70959L631.962,210.835a4.24687,4.24687,0,0,1-3.41438,1.88909C628.50825,212.72475,628.46887,212.72544,628.42948,212.72544Z"
        transform="translate(-262.59088 -154.70066)"
        fill="#fff"
      />
    </svg>
  );
};

export default MessageSentSvg;
