import React from 'react';

interface SideNavBarWidthContextProps {
  width: number;
}

const SideNavBarWidthContext = React.createContext<SideNavBarWidthContextProps>(
  { width: 0 },
);

export const useSideNavBarWidth = () => {
  const context = React.useContext(SideNavBarWidthContext);
  if (!context) {
    throw new Error(`useSideNavBarWidth must be used within a Navigation`);
  }
  return context;
};

export const SideNavBarWidthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navBarWidth, setNavBarWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    const navElement = document.getElementById('main-side-navigation');
    const handleSize = () => {
      setNavBarWidth(navElement?.offsetWidth ?? 0);
    };

    handleSize();

    if (!navElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(handleSize);
    resizeObserver.observe(navElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <SideNavBarWidthContext.Provider value={{ width: navBarWidth }}>
      {children}
    </SideNavBarWidthContext.Provider>
  );
};
