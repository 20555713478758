import seedrandom from 'seedrandom';

const PHI = 1.618033988749895;

export const generateColorFromString = (str: string) =>
  `hsl(${Math.floor(((seedrandom(str)() + 1 / PHI) % 1) * 360)}, 50%, 50%)`;

export const generateBackgroundColorFromString = (str: string) =>
  `hsl(${Math.floor(((seedrandom(str)() + 1 / PHI) % 1) * 360)}, 50%, 40%)`;

export const generateBackgroundColorDisabledFromString = (str: string) =>
  `hsl(${Math.floor(((seedrandom(str)() + 1 / PHI) % 1) * 360)}, 50%, 35%)`;

export const generateTextColorFromString = (str: string) =>
  `hsl(${Math.floor(((seedrandom(str)() + 1 / PHI) % 1) * 360)}, 50%, 95%)`;
