import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  wrapperClassName?: string;
  closeOnBackdropClick?: boolean;
}

const Modal = ({
  isOpen,
  close,
  children,
  closeOnBackdropClick = true,
  wrapperClassName = '',
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Suspense
      // To prevent showing global Suspense caused by Transition.Root when there is too much content to load
      fallback={
        <Dialog
          as="div"
          className="relative z-50"
          open={isOpen}
          onClose={close}
        >
          <div className="fixed inset-0 z-[100] bg-gray-500 bg-opacity-75 transition-opacity" />
        </Dialog>
      }
    >
      <Transition.Root show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={closeOnBackdropClick ? close : () => {}}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative m-4 max-w-[90vw] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:mx-0 sm:my-8 sm:p-8 ${wrapperClassName}`}
                >
                  <div className="absolute right-0 top-0 z-10 pr-4 pt-4 sm:block">
                    <button type="button" onClick={close}>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      <span className="sr-only">{t('common:a11y.close')}</span>
                    </button>
                  </div>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Suspense>
  );
};

export default Modal;
