import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  postRegister,
  postRequestNumberVerification,
} from '../../api/auth/auth';
import TextInputLabeledController from '../../components/inputs/text-input/text-input-labeled-controller';
import { RegisterUserRequest } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import SuccessToast from '../../components/toasts/success-toast';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import logo from '../../assets/logo-transparent.png';
import bg from '../../assets/sign-up-bg.jpg';
import CheckboxInputLabeledController from '../../components/inputs/checkbox-input/checkbox-input-labeled-controller';
import { TERMS_URL } from '../../constants/external';
import TextInputController from '../../components/inputs/text-input/text-input-controller';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS, SignUpMethods } from '../../constants/analytics';
import { isEmail, isPhone } from '../../constants/validation';
import { useSearchParams } from '../../hooks/use-search/use-search-params';
import { usePropagateParams } from '../../hooks/use-propagate-params/use-propagate-params';
import SelectInputLabeledArrowController from '../../components/inputs/select-input/select-input-arrow-controlled';
import Modal from '../../components/modal/modal';
import { Dialog } from '@headlessui/react';
import TextInputLabeled from '../../components/inputs/text-input/text-input-labeled';
import PageHeader from '../../components/header/page-header';

export interface RegisterUserForm extends Omit<RegisterUserRequest, 'scope'> {
  remember: boolean;
}

const DEFAULT_VALUES = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  organization_name: '',
  accept: false,
  referral_code: '',
  nip: '',
  organization_size: '',
};

const SMS_LIMIT = 3;
const ORGANIZATION_SIZE_OPTIONS = ['1-10', '11-50', '50+'];

export enum RegisterErrors {
  REGISTER_USER_ALREADY_EXISTS = 'REGISTER_USER_ALREADY_EXISTS',
  REGISTER_INVALID_SMS_CODE = 'REGISTER_INVALID_SMS_CODE',
}

const SignUp = () => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const { t } = useTranslation(['auth', 'common']);
  const location = useLocation() as {
    state: { from: { pathname: string } };
  };
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { ref, source } = useSearchParams<{ ref: string; source: string }>();
  const withParams = usePropagateParams();
  const [isVerifySmsOpen, setIsVerifySmsOpen] = React.useState(false);
  const [smsSent, setSmsSent] = React.useState({} as Record<string, number>);
  const [code, setCode] = React.useState('');
  const [cooldownTime, setCooldownTime] = React.useState(0);

  const steps = [
    {
      fields: [
        'organization_name',
        'nip',
        'organization_size',
        'referral_code',
      ],
    },
    {
      fields: [
        'email',
        'first_name',
        'last_name',
        'phone_number',
        'password',
        'accept',
      ],
    },
  ];

  const schema = React.useMemo(() => {
    const result = {
      email: isEmail(t, { required: true }),
      password: yup
        .string()
        .min(6, t('auth:fieldErrors.mustBeAtLeast6CharsLong')),
      first_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      last_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      accept: yup
        .boolean()
        .isTrue(t('auth:fieldErrors.mustBeAccepted'))
        .required(t('auth:fieldErrors.mustBeAccepted')),
      phone_number: isPhone(t, { required: true }),
      referral_code: yup.string(),
      organization_name: yup
        .string()
        .required(t('auth:fieldErrors.cannotBeEmpty')),
      nip: yup
        .string()
        .matches(/^[0-9]+$/, t('auth:fieldErrors.mustBeDigits'))
        .required(t('auth:fieldErrors.cannotBeEmpty'))
        .length(10, t('auth:fieldErrors.mustBe10CharsLong')),
      organization_size: yup
        .string()
        .oneOf(ORGANIZATION_SIZE_OPTIONS)
        .required(t('auth:fieldErrors.cannotBeEmpty')),
    } as any;

    const fieldsSoFar = steps.slice(0, currentStep).flatMap((s) => s.fields);

    return yup.object(
      fieldsSoFar.reduce((acc, field) => {
        acc[field] = result[field];
        return acc;
      }, {} as any),
    );
  }, [t, currentStep]);

  const { control, setValue, handleSubmit, getValues, watch } = useForm<
    RegisterUserForm & { accept: boolean }
  >({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (ref) {
      setValue('referral_code', ref);
    }
  }, [ref, setValue]);

  const { mutate: requestSms } = useMutation(postRequestNumberVerification, {
    onError: (e: AxiosError) => {
      defaultErrorToasts(e, t);
    },
  });

  const { mutate, isLoading } = useMutation(postRegister, {
    onSuccess: async (resp, data) => {
      if (resp.is_activated) {
        await analytics.track(ANALYTICS_EVENTS.USER_REGISTERED, {
          email: data.email,
          method: SignUpMethods.EMAIL,
          firstName: data.first_name,
          lastName: data.last_name,
          phoneNumber: data.phone_number,
          organizationName: data.organization_name,
          nip: data.nip,
          referral_code: data.referral_code,
          source,
          organization_size: data.organization_size,
        });
        toast.custom((toast) => (
          <SuccessToast
            {...toast}
            title={t('auth:alerts.signUpSuccessfulAndValidated.title')}
            body={t('auth:alerts.signUpSuccessfulAndValidated.body')}
          />
        ));
        navigate('/login');
      } else {
        toast.custom((toast) => (
          <SuccessToast
            {...toast}
            title={t('auth:alerts.signUpSuccessful.title')}
            body={t('auth:alerts.signUpSuccessful.body')}
          />
        ));
        navigate('/account-created');
      }
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;

      if (data.detail === RegisterErrors.REGISTER_USER_ALREADY_EXISTS) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.registerUserAlreadyExists.title')}
            body={t('auth:alerts.registerUserAlreadyExists.body')}
          />
        ));
        return;
      } else if (data.detail === RegisterErrors.REGISTER_INVALID_SMS_CODE) {
        setCode('');
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.registerInvalidSmsCode.title')}
            body={t('auth:alerts.registerInvalidSmsCode.body')}
          />
        ));
        return;
      }

      defaultErrorToasts(e, t);
    },
  });

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const startCooldown = () => {
    setCooldownTime(30);
    const timer = setInterval(() => {
      setCooldownTime((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const requestNewSmsCode = (phoneNumber: string) => {
    const smsSentSnapshot = { ...smsSent };

    if ((smsSentSnapshot[phoneNumber] ?? 0) < SMS_LIMIT) {
      requestSms({
        phone_number: `+48${phoneNumber}`,
      });

      smsSentSnapshot[phoneNumber] = (smsSentSnapshot[phoneNumber] ?? 0) + 1;
      setSmsSent(smsSentSnapshot);
      startCooldown();
    }
  };

  const onSubmit = (form: RegisterUserForm & { accept: boolean }) => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      return;
    }
    setIsVerifySmsOpen(true);
    requestNewSmsCode(form.phone_number);
  };

  const onSmsCodeSubmitted = (code: string) => {
    const { accept, referral_code, phone_number, ...rest } = getValues();
    mutate({
      ...rest,
      phone_number: `+48${phone_number}`,
      referral_code: referral_code === '' ? null : referral_code,
      sms_code: code,
      source,
    });
  };

  const phoneNumberWatch = watch('phone_number');

  const renderFields = () => {
    if (currentStep === 1) {
      return (
        <div className="space-y-4">
          <TextInputLabeledController
            label={t('fields.organization')}
            name="organization_name"
            control={control}
            labelClassName="mb-1"
            id="organization-name"
          />
          <TextInputLabeledController
            label={t('fields.nip')}
            name="nip"
            control={control}
            id="nip"
            labelClassName="mb-1"
          />
          <SelectInputLabeledArrowController
            name="organization_size"
            showLabel
            labelClassName="mb-1"
            options={ORGANIZATION_SIZE_OPTIONS.map((v) => ({
              label: v,
              value: v,
            }))}
            control={control}
            id="organization_size"
            label={t('fields.organizationSize')}
          />
          <div>
            <div className="mb-1 flex justify-between">
              <label
                htmlFor="referral-code"
                className="block text-sm font-medium text-gray-700"
              >
                {t('fields.referralCode')}
              </label>
              <span className="text-sm text-gray-500">
                {t('fields.optional')}
              </span>
            </div>
            <TextInputController
              name="referral_code"
              control={control}
              id="referral-code"
              disabled={!!ref}
            />
          </div>
        </div>
      );
    }

    if (currentStep === 2) {
      return (
        <div className="space-y-4">
          <TextInputLabeledController
            name="email"
            control={control}
            id="email"
            autoComplete="email"
            labelClassName="mb-1"
            label={t('fields.email')}
          />
          <div className="flex">
            <TextInputLabeledController
              name="first_name"
              control={control}
              id="first-name"
              autoComplete="first-name"
              labelClassName="mb-1"
              className="mr-4"
              label={t('fields.firstName')}
            />
            <TextInputLabeledController
              name="last_name"
              control={control}
              id="last-name"
              autoComplete="last-name"
              labelClassName="mb-1"
              label={t('fields.lastName')}
            />
          </div>
          <TextInputLabeledController
            name="phone_number"
            label={t('fields.phoneNumber')}
            control={control}
            id="phone-number"
            prefixIcon={<div className="sm:text-sm">+48</div>}
            autoComplete="phone"
          />
          <PasswordInputLabeledController
            name="password"
            control={control}
            id="password"
            autoComplete="password"
            labelClassName="mb-1"
            label={t('fields.password')}
          />
          <div className="flex flex-row">
            <CheckboxInputLabeledController
              name="accept"
              control={control}
              id="accept"
              changeOnLabelClick={false}
              label={
                (
                  <div>
                    {t('fields.accept')}{' '}
                    <a
                      href={TERMS_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="text-indigo-600 hover:text-indigo-500"
                    >
                      {t('fields.terms')}
                    </a>
                  </div>
                ) as any
              }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex h-full flex-row bg-gray-50">
      <PageHeader title={t('common:pageTitle.signUp')} />
      <Modal
        close={() => setIsVerifySmsOpen(false)}
        isOpen={isVerifySmsOpen}
        closeOnBackdropClick={false}
        wrapperClassName="w-96"
      >
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {t('auth:signUp.verifyPhone')}
            </Dialog.Title>
          </div>
        </div>
        <div className="mt-6 flex flex-col space-y-4">
          <TextInputLabeled
            id="sms-code"
            value={code}
            labelClassName="mb-1"
            onChange={(value) => setCode(value)}
            label={t('auth:fields.smsCode')}
          />
          <div className="flex justify-end">
            {(smsSent[phoneNumberWatch] ?? 0) >= SMS_LIMIT ? (
              <span className="text-sm text-gray-500">
                {t('auth:signUp.noMoreCodes')}
              </span>
            ) : cooldownTime > 0 ? (
              <span className="text-sm text-gray-500">
                {t('auth:signUp.canRequestNewCodeIn', {
                  seconds: cooldownTime,
                })}
              </span>
            ) : (
              <span
                className="cursor-pointer text-sm text-indigo-600 hover:text-indigo-500"
                onClick={() => requestNewSmsCode(phoneNumberWatch)}
              >
                {t('auth:signUp.requestNewCode')}
              </span>
            )}
          </div>
        </div>
        <div className="mt-4">
          <Button
            type="button"
            className="w-full px-4 py-2"
            isLoading={isLoading}
            onClick={() => onSmsCodeSubmitted(code)}
          >
            {t('auth:signUp.confirmPhone')}
          </Button>
        </div>
      </Modal>
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="w-full max-w-md space-y-8">
          <div className="mx-4 rounded-lg border border-gray-100 bg-white p-1 shadow-lg">
            <div className="px-6 pt-6">
              <div className="flex items-center justify-center">
                <img src={logo} alt="Logo" className="h-10" />
              </div>
              <h2 className="mt-4 text-center text-xl font-medium text-gray-900">
                {currentStep === 1
                  ? t('signUp.headerOrganization')
                  : t('signUp.headerUser')}
              </h2>
              <form
                className="mt-8 space-y-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="mb-6">{renderFields()}</div>
                <div className="flex justify-between space-x-4">
                  {currentStep !== 1 ? (
                    <Button
                      type="button"
                      onClick={prevStep}
                      variant="secondary"
                      className={`${
                        currentStep === 1 ? 'hidden' : ''
                      } flex-1 px-4 py-2`}
                    >
                      {t('signUp.back')}
                    </Button>
                  ) : null}

                  {currentStep === steps.length ? (
                    <Button type="submit" className="flex-1 px-4 py-2">
                      {t('signUp.signUp')}
                    </Button>
                  ) : (
                    <Button type="submit" className="w-full px-4 py-2">
                      {t('signUp.next')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
            <div className="mt-6 bg-gray-50 px-6 py-4 text-center">
              <p className="ml-auto mt-2 text-sm text-gray-600">
                {t('signUp.alreadyHave')}{' '}
                <Link
                  to={withParams('/login')}
                  state={location.state}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {t('signUp.logIn')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default SignUp;
