import React from 'react';

export interface TextInputProps {
  type?: string;
  value: string;
  onChange: (newVal: string) => void;
  autoComplete?: string;
  id?: string;
  isError?: boolean;
  errorMessage?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  placeholder?: string;
  saveErrorSpace?: boolean;
  disabled?: boolean;
}

const TextInput = ({
  value = '',
  onChange,
  id,
  autoComplete,
  type = 'text',
  isError = false,
  errorMessage = '',
  prefixIcon,
  suffixIcon,
  placeholder,
  saveErrorSpace = false,
  disabled = false,
}: TextInputProps) => {
  return (
    <>
      <div className="relative">
        <input
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value ?? ''}
          id={id}
          autoComplete={autoComplete}
          onChange={(e) => onChange(e.target.value)}
          className={`${prefixIcon ? 'pl-12 md:pl-10' : ''} ${
            suffixIcon ? 'pr-10' : ''
          } block w-full rounded-md shadow-sm focus:outline-none sm:text-sm ${
            disabled ? 'bg-gray-100' : ''
          } ${
            isError
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
          }`}
        />
        {prefixIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {prefixIcon}
          </div>
        )}
        {suffixIcon && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {suffixIcon}
          </div>
        )}
      </div>
      {(saveErrorSpace || isError) && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}
    </>
  );
};

export default TextInput;
