import Analytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';
import { axios } from '../api/axios';
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';

export const ANALYTICS_EVENTS = {
  APP_ERROR: 'app_error',
  COMMENT_ADDED: 'comment_added',
  COMMENT_REMOVED: 'comment_removed',
  USER_INVITED_TO_ORGANIZATION: 'user_invited_to_organization',
  PASSWORD_CHANGED: 'password_changed',
  AVATAR_UPLOADED: 'avatar_uploaded',
  AVATAR_REMOVED: 'avatar_removed',
  INITIAL_SETTINGS_SET: 'initial_settings_set',
  INITIAL_COMMUNICATION_CONSENTS_SET: 'initial_communication_consents_set',
  SETTINGS_CHANGED: 'settings_changed',
  MAP_SELECT_OPENED: 'map_select_opened',
  MAP_AREA_SELECTED: 'map_area_selected',
  ADVANCED_FILTERS_OPENED: 'advanced_filters_opened',

  // standard google event, has to follow strict data model
  USER_REGISTERED: 'sign_up',

  // standard google event, has to follow strict data model
  USER_LOGGED_IN: 'login',

  USER_LOGGED_OUT: 'logout',
  ACCOUNT_ACTIVATED: 'account_activated',
  FORGOT_PASSWORD: 'forgot_password',
  VIEW_SAVED: 'view_saved',
  VIEW_EDITED: 'view_edited',
  VIEW_DELETED: 'view_deleted',
  VIEW_USED: 'view_used',
  VIEW_SUBSCRIPTION_SET: 'view_subscription_set',
  OFFER_MENU_OPENED: 'offer_menu_opened',
  OFFER_SAVED: 'offer_saved',
  OFFER_UNSAVED: 'offer_unsaved',
  SEARCH_CRITERIA_CHANGED: 'search_criteria_changed',
  OFFER_ACTIVENESS_REFRESH_TRIGGERED: 'offer_activeness_refresh_triggered',
  OFFER_STATUS_ADDED: 'offer_status_added',
  OFFER_STATUS_DELETED: 'offer_status_deleted',
  OFFER_STATUS_ARCHIVED: 'offer_status_archived',
  CALL_LIST_OFFER_MOVED: 'call_list_offer_moved',
  CALL_LIST_COLUMN_ARCHIVED: 'call_list_column_archived',
  CALL_LIST_OFFER_DRAG_ARCHIVED: 'call_list_offer_drag_archived',
  USER_PRIVILEGE_CHANGED: 'user_privilege_changed',
  ON_BOARDING_MENU_OPENED: 'on_boarding_menu_opened',
  ON_BOARDING_MENU_CLOSED: 'on_boarding_menu_closed',
  DASHBOARD_MAP_OFFER_TYPE_FILTER_USED: 'dashboard_map_offer_type_filter_used',
  DASHBOARD_MAP_OFFER_SELL_TYPE_FILTER_USED:
    'dashboard_map_offer_sell_type_filter_used',
  DASHBOARD_MAP_OFFER_CLICKED: 'dashboard_map_offer_clicked',
  ORGANIZATION_MEMBER_ACTIVATED: 'organization_member_activated',
  ORGANIZATION_MEMBER_DEACTIVATED: 'organization_member_deactivated',
  FILTERS_PANEL_OPENED: 'filters_panel_opened',
  OFFERS_CATEGORIES_LIST_OPEN: 'offers_categories_list_open',
  ACTIVITY_MODAL_OPEN: 'activity_modal_open',
  OFFER_HISTORY_MODAL_OPEN: 'offer_history_modal_open',
  PHONE_CALL_SUMMARY_ADDED: 'phone_call_summary_added',
  PHONE_CALL_SUMMARY_MODAL_OPEN: 'phone_call_summary_modal_open',
  MANUAL_SMS_SENT: 'manual_sms_sent',
  SMS_CAMPAIGN_DELETED: 'sms_campaign_deleted',
  SMS_CAMPAIGN_ADDED: 'sms_campaign_added',
  SMS_CAMPAIGN_UPDATED: 'sms_campaign_updated',
  SMS_CAMPAIGN_ACTIVATED: 'sms_campaign_activated',
  SMS_CAMPAIGN_DEACTIVATED: 'sms_campaign_deactivated',
  SMS_CLICKED: 'sms_clicked',
  PDF_DOWNLOAD_SUCCESS: 'pdf_download_success',
  PDF_DOWNLOAD_START: 'pdf_download_start',
  PDF_DOWNLOAD_ERROR: 'pdf_download_error',
  CLIENT_SEARCH_CREATE_FAILED: 'client_search_create_failed',
  CLIENT_SEARCH_ADDED: 'client_search_added',
  CLIENT_SEARCH_UPDATED: 'client_search_updated',
  CLIENT_SEARCH_UPDATE_FAILED: 'client_search_update_failed',
  CLIENT_SEARCH_ADD_FROM_OFFER_PANEL_OPENED:
    'client_search_add_from_offer_panel_opened',
  CLIENT_SEARCH_ADD_FROM_OFFER_SUBMITTED:
    'client_search_add_from_offer_submitted',
  CLIENT_SEARCH_ADD_FROM_OFFER_FAILED: 'client_search_add_from_offer_failed',
  CLIENT_SEARCH_OFFERS_LIST_SHARED: 'Client_search_offers_list_shared',
  ORGANIZATION_LOGO_UPLOADED: 'organization_logo_uploaded',
  ORGANIZATION_LOGO_REMOVED: 'organization_logo_removed',
  ORGANIZATION_SETTINGS_CHANGED: 'organization_settings_changed',
  OFFER_MAP_OPENED: 'offer_map_opened',
  OFFER_LIST_OPENED: 'offer_list_opened',
  OFFER_EXPORT_MAP_OPENED: 'offer_export_map_opened',
  OFFER_EXPORT_LIST_OPENED: 'offer_export_list_opened',
  MONITORED_BOOK_ADDED: 'monitored_book_added',
  MONITORED_BOOK_IMPORT: 'monitored_book_import',
  MONITORED_BOOK_REMOVED: 'monitored_book_removed',
  MONITORED_BOOK_OPENED: 'monitored_book_opened',
  MONITORED_BOOK_UPDATED: 'monitored_book_updated',
  MONITORED_BOOK_SETTINGS_CHANGE: 'monitored_book_settings_change',
  FAST_SEARCH_OPENED: 'fast_search_opened',
  FAST_SEARCH_PERFORMED: 'fast_search_performed',
  FAST_SEARCH_FAILED: 'fast_search_failed',
  FAST_SEARCH_PREVIEW_SETTLED: 'fast_search_preview_settled',
  FAST_SEARCH_OFFER_CLICKED: 'fast_search_offer_clicked',
  AI_SEARCH_OPENED: 'ai_search_opened',
  AI_SEARCH_PERFORMED: 'ai_search_performed',
  TEAM_CREATED: 'team_created',
  TEAM_UPDATED: 'team_updated',
  ASSIGNED_TO_TEAM: 'assigned_to_team',
  REMOVED_FROM_TEAM: 'removed_from_team',
  BRANCH_CREATED: 'branch_created',
  BRANCH_UPDATED: 'branch_updated',
  ASSIGNED_TO_BRANCH: 'assigned_to_branch',
  REMOVED_FROM_BRANCH: 'removed_from_branch',
  SUBSCRIPTION_PAYMENT_INITIATED: 'subscription_payment_initiated',
  SUBSCRIPTION_PAYMENT_SUCCEEDED: 'subscription_payment_succeeded',
  COMMUNICATION_CONSENTS_UPDATED: 'communication_consents_updated',
  OFFER_COLLECTION_CREATED: 'offer_collection_created',
  OFFER_COLLECTION_UPDATED: 'offer_collection_updated',
  OFFER_COLLECTION_DELETED: 'offer_collection_deleted',
  ADDED_OFFER_TO_OFFER_COLLECTION: 'added_offer_to_offer_collection',
  DELETED_OFFER_FROM_OFFER_COLLECTION: 'deleted_offer_from_offer_collection',
  CUSTOMER_FEEDBACK_SENT: 'customer_feedback_sent',
  CUSTOMER_FEEDBACK_ERROR: 'customer_feedback_error',
  MARKETING_CONSENT_SENT: 'marketing_consent_sent',
  MARKETING_CONSENT_ACCEPTED: 'marketing_consent_accepted',
  MARKETING_CONSENT_REVOKED: 'marketing_consent_revoked',
  LEAD_STATUS_UPDATED: 'lead_status_updated',
  LEAD_STATUS_UPDATE_FAILED: 'lead_status_update_failed',
  LEAD_NOTES_UPDATED: 'lead_notes_updated',
  LEAD_NOTES_UPDATE_FAILED: 'lead_notes_update_failed',
  LEAD_ASSIGMENT_UPDATE: 'lead_assignment_updated',
  LEAD_ASSIGMENT_UPDATE_FAILED: 'lead_assignment_update_failed',
  LEAD_ASSIGMENT_DELETE: 'lead_assignment_deleted',
  LEAD_ASSIGMENT_DELETE_FAILED: 'lead_assignment_delete_failed',

  // standard google event name, used for generic purchases
  PURCHASE: 'purchase',
};

export const restAnalyticsPlugin = (): AnalyticsPlugin => {
  return (['page', 'track', 'identify'] as const).reduce(
    (p, c) => {
      p[c] = ({ payload }: { payload: any }) => {
        axios.post('/event', {
          payload: payload,
        });
        return;
      };
      return p;
    },
    {
      name: 'rest-analytics-plugin',
      loaded: () => true,
    } as AnalyticsPlugin,
  );
};

export const hotJarPlugin = ({
  hotJarVersion,
  hotJarId,
}: {
  hotJarId: number;
  hotJarVersion: number;
}): AnalyticsPlugin => {
  return {
    name: 'hotjar-analytics-plugin',
    initialize: () => hotjar.initialize(hotJarId, hotJarVersion),
    page: ({ payload }: { payload: any }) =>
      hotjar.stateChange(payload.properties.path),
    track: ({ payload }: { payload: any }) => hotjar.event(payload.event),
    identify: ({ payload }: { payload: any }) =>
      hotjar.identify(payload.userId, payload.traits),
    loaded: () => hotjar.initialized(),
    ready: () => hotjar.initialized(),
  };
};

// standard facebook event mapping
const META_EVENT_RESOLVERS = {
  [ANALYTICS_EVENTS.USER_REGISTERED]: () =>
    ReactPixel.track('CompleteRegistration'),
  [ANALYTICS_EVENTS.SUBSCRIPTION_PAYMENT_SUCCEEDED]: (data: any) => {
    if (data.is_test_mode) {
      return;
    }
    return ReactPixel.track('Purchase', {
      value: data.price_total_gross,
      currency: data.currency,
    });
  },
};

export const metaPixelPlugin = ({
  pixelId,
}: {
  pixelId: string;
}): AnalyticsPlugin => {
  let initialized = false;
  return {
    name: 'facebook-pixel',
    config: { pixelId },
    initialize: () => {
      ReactPixel.init(pixelId);
      initialized = true;
    },
    page: () => ReactPixel.pageView(),
    track: ({ payload }: { payload: any }) => {
      const { event, properties } = payload;
      if (META_EVENT_RESOLVERS[event]) {
        META_EVENT_RESOLVERS[event](properties);
      }
    },
    ready: () => initialized,
    loaded: () => initialized,
  };
};

export const ANALYTICS = Analytics({
  app: 'application',
  debug: !import.meta.env.VITE_APP_GA_MEASUREMENT_ID,
  plugins: import.meta.env.VITE_APP_GA_MEASUREMENT_ID
    ? [
        restAnalyticsPlugin(),
        googleAnalytics({
          measurementIds: [import.meta.env.VITE_APP_GA_MEASUREMENT_ID],
        }),
        hotJarPlugin({
          hotJarId: Number(import.meta.env.VITE_APP_HOT_JAR_ID),
          hotJarVersion: 6,
        }),
        metaPixelPlugin({
          pixelId: import.meta.env.VITE_APP_META_PIXEL_ID,
        }),
      ]
    : [restAnalyticsPlugin()],
}) as AnalyticsInstance;

export enum SignUpMethods {
  EMAIL = 'Email',
  ORGANIZATION = 'Organization',
}
