import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, MicrophoneIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import SpinnerIcon from '../spinner/spinner-icon';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useMutation } from '@tanstack/react-query';
import { postAiViews } from '../../api/views/views';
import { useNavigate } from 'react-router-dom';
import { buildOfferTypeUrl } from '../../models/offer';
import { FilterCondition } from '../../models/filter';
import { useUser } from '../../context/auth/use-user';
import 'regenerator-runtime/runtime';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import './animation.css';
import { ViewType } from '../../hooks/use-offers/use-offer-list-search-params';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  placeholder?: string;
}

const AiSearchSearchbar = ({ isOpen, setIsOpen, placeholder }: Props) => {
  const analytics = useAnalytics();
  const { t } = useTranslation(['navigation']);
  const [value, setValue] = React.useState('');
  const navigate = useNavigate();
  const user = useUser();

  const ref = React.useRef<HTMLButtonElement>(null);
  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  React.useEffect(() => {
    setValue(transcript);
  }, [transcript]);

  React.useEffect(() => {
    if (finalTranscript) {
      ref.current?.click();
    }
  }, [finalTranscript]);

  const { mutateAsync, isLoading, isError } = useMutation(postAiViews);

  React.useEffect(() => {
    if (isOpen) {
      analytics.track(ANALYTICS_EVENTS.AI_SEARCH_OPENED);
    }
  }, [isOpen]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    resetTranscript();
    const currVal = value.trim();
    if (!currVal) {
      return;
    }
    const result = await mutateAsync({
      body: { text: currVal, base_filters: [] },
    });

    setIsOpen(false);

    const filters = [
      ...result.filters,
      ...user.personalizedFilters(),
      {
        field: 'date_smart_last_seen',
        value: 14,
        condition: FilterCondition.LAST_N_DAYS,
      },
    ];

    if (!filters.find((f) => f.condition === FilterCondition.PROXIMITY)) {
      filters.push({
        field: 'location',
        value: 0,
        condition: FilterCondition.PROXIMITY,
      });
    }

    if (!filters.find((f) => f.field === 'address')) {
      filters.push(...user.defaultLocation());
    }

    const sorts = result.sorts.length ? result.sorts : user.defaultSorts();
    const viewType = result.is_map_view ? ViewType.MAP : ViewType.LIST;

    analytics.track(ANALYTICS_EVENTS.AI_SEARCH_PERFORMED, {
      query: currVal,
      sorts,
      filters,
      modelSorts: result.sorts,
      modelFilters: result.filters,
      modelOfferTypes: result.offer_types,
      modelViewType: viewType,
    });

    navigate(
      buildOfferTypeUrl({
        offerTypes: result.offer_types,
        filters,
        sorts,
        viewType,
      }) + '&reload=true',
    );
  };

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      afterLeave={() => {
        setValue('');
        SpeechRecognition.stopListening();
      }}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl transition-all">
              <form className="relative" onSubmit={onSubmit}>
                <input
                  className="inset-0 h-12 w-full border-0 bg-transparent pl-4 pr-16 text-gray-900 outline-none ring-0 placeholder:text-gray-400 sm:text-sm"
                  placeholder={
                    placeholder ?? t('navigation:userMenu.aiSearch.placeholder')
                  }
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                />
                <button className="absolute right-4 top-3.5" ref={ref}>
                  {isLoading ? (
                    <SpinnerIcon className="h-5 w-5 text-indigo-600" />
                  ) : (
                    <div className="flex flex-row">
                      {browserSupportsSpeechRecognition ? (
                        <MicrophoneIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (listening) {
                              SpeechRecognition.stopListening();
                            } else {
                              SpeechRecognition.startListening();
                            }
                          }}
                          className={`mr-2 h-5 w-5 ${
                            listening ? 'text-indigo-600' : 'text-gray-400'
                          }`}
                        />
                      ) : null}
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </button>
                <div className="border-t border-gray-200 pb-2 pl-4 pt-1">
                  {isLoading ? (
                    <div className="dank-ass-loader my-4">
                      <div className="row">
                        <div className="arrow up outer outer-18"></div>
                        <div className="arrow down outer outer-17"></div>
                        <div className="arrow up outer outer-16"></div>
                        <div className="arrow down outer outer-15"></div>
                        <div className="arrow up outer outer-14"></div>
                      </div>
                      <div className="row">
                        <div className="arrow up outer outer-1"></div>
                        <div className="arrow down outer outer-2"></div>
                        <div className="arrow up inner inner-6"></div>
                        <div className="arrow down inner inner-5"></div>
                        <div className="arrow up inner inner-4"></div>
                        <div className="arrow down outer outer-13"></div>
                        <div className="arrow up outer outer-12"></div>
                      </div>
                      <div className="row">
                        <div className="arrow down outer outer-3"></div>
                        <div className="arrow up outer outer-4"></div>
                        <div className="arrow down inner inner-1"></div>
                        <div className="arrow up inner inner-2"></div>
                        <div className="arrow down inner inner-3"></div>
                        <div className="arrow up outer outer-11"></div>
                        <div className="arrow down outer outer-10"></div>
                      </div>
                      <div className="row">
                        <div className="arrow down outer outer-5"></div>
                        <div className="arrow up outer outer-6"></div>
                        <div className="arrow down outer outer-7"></div>
                        <div className="arrow up outer outer-8"></div>
                        <div className="arrow down outer outer-9"></div>
                      </div>
                    </div>
                  ) : (
                    <p
                      className={`whitespace-pre text-xs ${
                        isError ? 'text-red-600' : 'text-gray-500'
                      }`}
                    >
                      {isError
                        ? t('navigation:userMenu.aiSearch.error')
                        : t('navigation:userMenu.aiSearch.hint')}
                    </p>
                  )}
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AiSearchSearchbar;
